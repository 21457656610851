import { Autocomplete, Box, Button, ButtonGroup, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { DateTime } from 'luxon'
import { CurrencySymbol } from '../../../../Utility/Constants'
import { DataGrid } from '@mui/x-data-grid'
import { AmountFormat, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'

export default class DaywiseCategorySales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            daywiseSalesData: [],
            serviceWise: false,
            totalQty: 0,
            totalAmt: 0,
            cash: 0,
            card: 0,
            bank: 0,
            discount: 0,
            FilterOpen: false,
            loading: false,
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            department: [],
            searchServiceName: '',
            searchServiceHead: '',
            printLoad: false,
            departmentList: [
                { label: 'Out Patient', value: 'Outpatient' },
                { label: 'In patient', value: 'Inpatient' },
                { label: 'Radiology', value: 'Radiology' },
            ]
        }
    }

    componentDidMount() {
        this.getDaywiseCategorySalesData()
        this.getDayWiseDepartmentList()
    }

    getDaywiseCategorySalesData = () => {
        try {
            let states = this.state
            let department = [];
            let reportType = this.state.serviceWise ? 'Service-Wise' : 'Head-Wise'
            this.state.department?.forEach(element => department?.push(element?.value))
            this.setState({ loading: true })
            RestAPIService.getAll(
                Serviceurls.FO_DAY_WISE_CATEGORY_SALES_REPORT +
                '?from_date=' + states.fromDate +
                '&to_date=' + states.toDate +
                '&report_type=' + reportType +
                '&department=' + department +
                '&service_type=' + states.searchServiceHead +
                '&service_name=' + states.searchServiceName
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        daywiseSalesData: response.data.data,
                        totalAmt: response.data.total_amount,
                        totalQty: response.data.total_count,
                        discount: response.data.total_discount_amount,
                        loading: false
                    })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getDaywiseCategorySalesPrint = () => {
        try {
            let states = this.state
            let department = [];
            let reportType = this.state.serviceWise ? 'Service-Wise' : 'Head-Wise'
            this.state.department?.forEach(element => department?.push(element?.value))
            RestAPIService.getAll(
                Serviceurls.FO_DAY_WISE_CATEGORY_SALES_REPORT +
                '?from_date=' + states.fromDate +
                '&to_date=' + states.toDate +
                '&report_type=' + reportType +
                '&department=' + department +
                '&service_type=' + states.searchServiceHead +
                '&service_name=' + states.searchServiceName +
                '&export_type=pdf'
            ).then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
                this.setState({ printLoad: false })
            }).catch((error) => {
                this.setState({ printLoad: false })
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.setState({ printLoad: false })
            this.errorMessage(error.message)
        }
    }

    getDayWiseDepartmentList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_DAY_WISE_DEPARTMENT_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        data?.forEach(element => { this.state.departmentList?.push({ label: element, value: element }) })
                        this.setState({ departmentList: this.state.departmentList })
                    }
                }).catch((error) => {
                    this.setState({ printLoad: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            disableClearable
                            options={this.state.departmentList}
                            getOptionLabel={(option) => (typeof (option) === "string" ? option : option?.label)}
                            value={this.state.department}
                            onChange={(event, newValue) => {
                                this.setState({ department: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={("Select Department")}
                                    autoComplete='off'
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            size='small'
                            label='Enter Service Head'
                            value={this.state.searchServiceHead}
                            onChange={(e) => this.setState({ searchServiceHead: e.target.value })}
                        />
                    </Box>
                    {this.state.serviceWise ?
                        <Box className='eMed_rts_dropdown'>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                size='small'
                                label='Enter Service Name'
                                value={this.state.searchServiceName}
                                onChange={(e) => this.setState({ searchServiceName: e.target.value })}
                            />
                        </Box> : null
                    }
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterOpen: false,
                                loading: true,
                                searchServiceHead: '',
                                searchServiceName: '',
                                department: []
                            }, () => this.getDaywiseCategorySalesData())
                        }}
                        id="eMed_Config_btn">{("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.setState({
                                FilterOpen: false,
                                loading: true
                            }, () => this.getDaywiseCategorySalesData())
                        }}
                        id="eMed_Config_btn">{("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.daywiseSalesData?.forEach((element, index) => element.sno = index + 1)
        const columns = this.state.serviceWise ? [
            {
                field: 'invoice_date', headerName: 'Date', flex: 0.166,
                renderCell: (params) => (<Box>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
            },
            {
                field: 'department', headerName: 'Department', flex: 0.166,
                renderCell: (params) => {
                    return (
                        <Box>
                            {params?.row?.department ? params?.row?.department?.length > 20 ?
                                <Tooltip placement='top' title={params?.row?.department}><Typography>{params?.row?.department?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                <Typography>{params?.row?.department}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'service_type', headerName: 'Service Head', flex: 0.166,
                renderCell: (params) => {
                    return (
                        <Box>
                            {params?.row?.service_type ? params?.row?.service_type?.length > 20 ?
                                <Tooltip placement='top' title={params?.row?.service_type}><Typography>{params?.row?.service_type?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                <Typography>{params?.row?.service_type}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'service_name', headerName: 'Service Name', flex: 0.166,
                renderCell: (params) => {
                    return (
                        <Box>
                            {params?.row?.service_name ? params?.row?.service_name?.length > 20 ?
                                <Tooltip placement='top' title={params?.row?.service_name}><Typography>{params?.row?.service_name?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                <Typography>{params?.row?.service_name}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'count', headerName: 'Quantity', flex: 0.166, type: 'number',
                renderCell: (params) => (<Box>{params?.row?.count ? params?.row?.count : '0'}</Box>)
            },
            {
                field: 'amount', headerName: `Amount ${CurrencySymbol}`, flex: 0.166, type: 'number',
                renderCell: (params) => (<Box>{params?.row?.amount ? AmountFormat(params?.row?.count) : '0.00'}</Box>)
            },
        ] : [
            {
                field: 'invoice_date', headerName: 'Date', flex: 0.2,
                renderCell: (params) => (<Box>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
            },
            {
                field: 'department', headerName: 'Department', flex: 0.2,
                renderCell: (params) => {
                    return (
                        <Box>
                            {params?.row?.department ? params?.row?.department?.length > 20 ?
                                <Tooltip placement='top' title={params?.row?.department}><Typography>{params?.row?.department?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                <Typography>{params?.row?.department}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'service_type', headerName: 'Service Head', flex: 0.2,
                renderCell: (params) => {
                    return (
                        <Box>
                            {params?.row?.service_type ? params?.row?.service_type?.length > 20 ?
                                <Tooltip placement='top' title={params?.row?.service_type}><Typography>{params?.row?.service_type?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                <Typography>{params?.row?.service_type}</Typography> : '-'}
                        </Box>
                    )
                }
            },
            {
                field: 'count', headerName: 'Quantity', flex: 0.166, type: 'number',
                renderCell: (params) => (<Box>{params?.row?.count ? params?.row?.count : '0'}</Box>)
            },
            {
                field: 'amount', headerName: `Amount ${CurrencySymbol}`, flex: 0.166, type: 'number',
                renderCell: (params) => (<Box>{params?.row?.amount ? AmountFormat(params?.row?.amount) : '0.00'}</Box>)
            },
        ]
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '79dvh' }}>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard('Total Quantity', this.state.totalQty)}
                        {AmountsCard('Total Amount', this.state.totalAmt)}
                        {/* {AmountsCard('Cash', this.state.cash)}
                        {AmountsCard('Card', this.state.card)}
                        {AmountsCard('Bank', this.state.bank)}
                        {AmountsCard('Discount', this.state.discount)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <ButtonGroup>
                            <Button variant={!this.state.serviceWise ? 'contained' : 'outlined'} sx={{ width: "8.5vw" }}
                                onClick={() => {
                                    this.setState({
                                        serviceWise: false, searchServiceHead: '',
                                        searchServiceName: '', department: [],
                                        fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        dateIndex: 1, loading: true
                                    }, () => { this.getDaywiseCategorySalesData() })
                                }}>{("Head-Wise")}</Button>
                            <Button variant={this.state.serviceWise ? 'contained' : 'outlined'} sx={{ width: "8.5vw" }}
                                onClick={() => {
                                    this.setState({
                                        serviceWise: true, searchServiceHead: '',
                                        searchServiceName: '', department: [],
                                        fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        dateIndex: 1, loading: true
                                    }, () => { this.getDaywiseCategorySalesData() })
                                }}>{("Service-Wise")}</Button>
                        </ButtonGroup>
                        <Box component={"div"} className='eMed_Filter_Icon_div' mr={'0.5dvw'}>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Tooltip title={("Print")} placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ printLoad: true }, () => this.getDaywiseCategorySalesPrint()) }} sx={{ mr: '0.5dvw' }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.daywiseSalesData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {'No Records To Be Shown'}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.loading}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
