import { Box, Button, Drawer, Grow, Stack, Tooltip ,Typography} from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard, AmountsCardWithCount } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class SalesDetailed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            salesDetailedData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "patientName": '',
                "drugName": '',
                'schedule': '',
                'groupName': null,
                "isSheduleOnly": false,
                "days":null,
                'billFrom': '',
                'billTo': '',
                "dosageType": [],
                "OPSelected": true,
                "IPSelected": true,
                "SelfSelected": true,
            },
            pageName: 'salesDetailed',
            filterOpen: false,
            totalAmount: 0,
            cgst: 0,
            sgst: 0,
            discountAmount: 0,
            netAmount: 0,
            isLoader: false,
            scheduled_Group :"",
            drug_schedule :"",
            inPatientCount : 0,
            outPatientCount :0,
            Otccount :0,
            showBox : false,
            ipNetAmt :0,
            opNetAmt :0,
            OtcNetAmt :0,
            rowCount :0,
        }
    }

    componentDidMount() {
        this.getSalesDetailedReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page:0,
        }, () => { this.getSalesDetailedReportData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getSalesDetailedReportData = () => {
        try {
            let states = this.state
            let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            this.LoaderFunction(true);
            let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.getAll(Serviceurls.PHARMA_SALES_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&patient_name=${this.state.filterDatas.patientName}&drug_name=${brandName}&is_scheduled=${this.state.filterDatas.isSheduleOnly}&schedule=${this.state.filterDatas.schedule}&group_name=${group}&bill_no_from=${this.state.filterDatas.billFrom}&bill_no_to=${this.state.filterDatas.billTo}&dosage_type=${this.state.filterDatas.dosageType}&module_type=${patientType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            salesDetailedData: response.data.data?.result,
                            totalAmount: response.data.data?.total_amount,
                            cgst: response.data.data?.total_cgst_amount,
                            sgst: response.data.data?.total_sgst_amount,
                            discountAmount: response.data.data?.total_discount_amount,
                            netAmount: response.data.data?.total_net_amount,
                            rowCount: response.data.data?.total_count,
                            inPatientCount: response.data.data?.total_ip_count,
                            outPatientCount: response.data.data?.total_op_count,
                            Otccount: response.data.data?.total_pharmacy_count,
                            ipNetAmt: response.data.data?.total_ip_net_amount,
                            opNetAmt: response.data.data?.total_op_net_amount,
                            OtcNetAmt: response.data.data?.total_pharmacy_net_amount,
                            roundOff: response.data.data?.total_round_off_amount,
                        },()=>{
                            this.state.salesDetailedData?.forEach((element)=> element.scheduled_Group = `${element?.drug_schedule ? element?.drug_schedule : '-'} / ${element?.group ? element?.group : '-'}`)
                            this.setState({salesDetailedData : this.state.salesDetailedData})
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSalesDetailedExcel = () => {
        try {
            this.setState({disableBtn: true})
            let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.excelGet(Serviceurls.PHARMA_SALES_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&patient_name=${this.state.filterDatas.patientName}&drug_name=${brandName}&is_scheduled=${this.state.filterDatas.isSheduleOnly}&schedule=${this.state.filterDatas.schedule}&group_name=${group}&bill_no_from=${this.state.filterDatas.billFrom}&bill_no_to=${this.state.filterDatas.billTo}&dosage_type=${this.state.filterDatas.dosageType}&module_type=${patientType}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }

    getSalesDetailedPrint = () => {
        try {
            let group = this.state.filterDatas?.groupName?.group ? this.state.filterDatas?.groupName?.group : ''
            let brandName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            this.setState({disableBtn: true})
            let patientType = []
            if(this.state.filterDatas.OPSelected){
              patientType?.push("op")
            }
            if(this.state.filterDatas.IPSelected){
              patientType?.push("ip")
            }
            if(this.state.filterDatas.SelfSelected){
              patientType?.push("pharmacy")
            }
            RestAPIService.PrintPDF(Serviceurls.PHARMA_SALES_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&patient_name=${this.state.filterDatas.patientName}&drug_name=${brandName}&is_scheduled=${this.state.filterDatas.isSheduleOnly}&schedule=${this.state.filterDatas.schedule}&group_name=${group}&bill_no_from=${this.state.filterDatas.billFrom}&bill_no_to=${this.state.filterDatas.billTo}&dosage_type=${this.state.filterDatas.dosageType}&module_type=${patientType}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    render() {
        const { t } = this.props
        this.state.salesDetailedData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'invoice_number', headerName: "Bill No", flex: 0.066,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 8 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{'...' + params?.row?.invoice_number?.slice(10, 20)}</div></Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            {
                field: 'invoice_date', headerName: "Bill Date", flex: 0.066, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let invoice_date = params?.row?.invoice_date ? splitDate(params?.row?.invoice_date) : "-";
                    return (<Box component={'div'}>
                        {invoice_date?.length > 8 ? <Tooltip placement='top' title={invoice_date} arrow><div>{invoice_date?.slice(0, 8) + '...'}</div></Tooltip> :
                            params?.row?.invoice_date ? invoice_date : '-'}
                    </Box>)
                }
            },
               {
                field: 'patient_name', headerName: "Patient Details", flex: 0.066,
                renderCell: (params) => {
                  let patientName =  params?.row?.patient_name ? params?.row?.patient_name  :"-"
                  let accNumAndMob =  (params?.row?.uhid ?  params?.row?.uhid : "-" ) + "|" + (params.row?.mobile_no ? params.row?.mobile_no : "-")
                  return (
                    <Box component={'div'} display={'flex'} emed_tid='emed_phar_pat_dtls' >
                      <Box component={'div'}>
                        {patientName ? patientName?.length > 15 ?
                          <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 15) + "..."}</Typography></Tooltip> :
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                        {accNumAndMob ? accNumAndMob?.length > 15 ?
                          <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 15) + "..."}</Typography></Tooltip> :
                          <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
                      </Box>
                    </Box>
                  )
                }
              },
            {
                field: 'module_type', headerName: "Patient Type", flex: 0.066,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.module_type ? params?.row?.module_type : '-'}</Box>)
            },
            {
                field: 'doctor_name', headerName: "Doctor Name", flex: 0.066,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.doctor_name?.length > 8 ? <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.doctor_name ? params?.row?.doctor_name : '-'}
                </Box>)
            },
            {
                field: 'brand_name', headerName: 'Brand Name', flex: 0.066,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.brand_name?.length > 8 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.brand_name ? params?.row?.brand_name : '-'}
                </Box>)
            },
            { field: 'dosage_strength', headerName: 'Dosage Strength', flex: 0.066, align: "center", headerAlign: "center" },
            {
                field: 'dosage_type', headerName: 'Dosage Type', flex: 0.066,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.dosage_type?.length > 8 ? <Tooltip placement='top' title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.dosage_type ? params?.row?.dosage_type : '-'}
                </Box>)
            },
            // { field: 'drug_schedule', headerName: 'Schedule', flex: 0.0625 },
            // { field: 'group', headerName: 'Group', flex: 0.0625, 
            // renderCell: (params) => (<Box component={'div'}>{params?.row?.group ? params?.row?.group : '-'}</Box>) },
            {
                field: 'drug_schedule', headerName: 'Schedule/Group', flex: 0.066,
                renderCell: (params) => (
                    (params.row.group || params.row.drug_schedule) ? params.row.scheduled_Group.length > 11 ?
                        <Tooltip placement='top' title={params?.row?.scheduled_Group} arrow><div>{params?.row?.scheduled_Group?.slice(0, 11) + "..."}</div></Tooltip> :
                        params.row.scheduled_Group : "-")
            },
            // { field: 'hsn_code', headerName: 'HSN Code', flex: 0.066, align: "center", headerAlign: "center" },
            {
                field: 'hsn_code', headerName: 'HSN Code', flex: 0.066, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.hsn_code?.length > 5 ? <Tooltip placement='top' title={params?.row?.hsn_code} arrow><div>{params?.row?.hsn_code?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.hsn_code ? params?.row?.hsn_code : '-'}
                </Box>)
            },
            { field: 'batch_no', headerName: 'Batch No', flex: 0.066, align: "center", headerAlign: "center" },
            {
                field: 'expiry_date', headerName: 'Expiry Date', flex: 0.066, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.expiry_date ? (params?.row?.expiry_date) : '-'}</Box>)
            },
            { field: 'quantity', headerName: 'Quantity', flex: 0.066, type: 'number' },
            { field: 'total_dicount_amount', headerName: `${"Discount Amount"} (${CurrencySymbol})`, flex: 0.066, type: 'number' },
            { field: 'total_net_amount', headerName: `${"Net Amount"} (${CurrencySymbol})`, flex: 0.066, type: 'number' },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        <Box sx={{cursor: 'pointer'}} onClick={() => { this.setState({ showBox: !this.state.showBox, }) }}>{AmountsCardWithCount(t("Net Amount"), this.state.netAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary, 'rec_recev_amt',"10vw",this.state.rowCount,true)}</Box>
                        {AmountsCardWithCount("In Patient", this.state.ipNetAmt || 0, false, "white", false, null, 'rpt_saleOver_net_amt', "10vw", this.state.inPatientCount, true)}
                        {AmountsCardWithCount("Out Patient", this.state.opNetAmt || 0, false, "white", false, null, 'rpt_saleOver_net_amt', "10vw", this.state.outPatientCount, true)}
                        {AmountsCardWithCount("OTC", this.state.OtcNetAmt || 0, false, "white", false, null, 'rpt_saleOver_net_amt', "10vw", this.state.Otccount, true)}
                        <Box component={'div'} sx={{ width: '30vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center' }} >
                            {this.state.showBox ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard("Taxable Amount", this.state.totalAmount, false, "white", false, Colors.Primary, 'rpt_saleOver_tax_amt', "10vw")}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard("CGST", this.state.cgst, false, "white", false, Colors.Primary, 'rpt_saleOver_cgst', "10vw")}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard("SGST", this.state.sgst, false, "white", false, Colors.Primary, 'rpt_saleOver_sgst', "10vw")}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, Colors.Primary, 'rpt_saleOver_dis_amt', "10vw")}</Grow>
                                    {/* <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard("Round Off", this.state.roundOff, false, "white", false, Colors.Primary, 'rpt_saleOver_rnd_amt', "10vw")}</Grow> */}
                                </Box> : null}
                        </Box>
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_saleDlt_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleDlt_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getSalesDetailedPrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_saleDlt_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getSalesDetailedExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.salesDetailedData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getSalesDetailedReportData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getSalesDetailedReportData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calGrp={true} calDosType={true}/>
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(SalesDetailed)