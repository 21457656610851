import { Box, Button, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import './IpPatientBillDetails.css';
import { Colors } from '../../../../../Styles/Colors';
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../../Components/Common Components/CommonComponents';
import { getCachevalue, localGetItem, setCachevalue } from '../../../../../Utility/Services/CacheProviderService';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { AmountFormat, CheckAccessFunc, ContentInTooltip } from '../../../../../Components/CommonFunctions/CommonFunctions';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../../Components/Loader';
import { CurrencySymbol } from '../../../../../Utility/Constants';

class IpPatientSavedBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            IpPatientSavedBill: [],
            service_amount: "",
            attender_mobile: "",
            attender_name: "",
            paid_amount: "",
            balance_amount: "",
            advance_amount: "",
            ApproxAmount: 0,
            selectedIndex: null,
            ipSavedBillId: '',
            billDeleteClicked: false,
            selectedDeleteID: {},
            disable:false,
            ApprovedAmt: 0,
            withOutAppointment: {},
        }
    }
    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        let appointmentDetail =  JSON.parse(getCachevalue("withoutAppointment"));
        this.setState({
            ipPatientData: patientData,
            withOutAppointment: appointmentDetail?.patient_id === patientData?.patient_id ? appointmentDetail : {}
        }, () => {
            this.getPatientData()
            this.getApproxCharges()
            this.getIpPatientSavedBillDetails()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPatientData = () => {
        const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
        try {
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            service_amount: response.data.data[0].service_amount,
                            balance_amount: response.data.data[0].balance_amount,
                            paid_amount: response.data.data[0].paid_amount,
                            advance_amount: response.data.data[0].advance_amount,
                            attender_mobile: response.data.data[0].attender_mobile,
                            attender_name: response.data.data[0].attender_name,
                            ApprovedAmt: response.data.data[0].claimed_amount,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getApproxCharges = () => {
        try {
            const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
            RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ApproxAmount: response.data.data?.approx_room_charge
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getIpPatientSavedBillDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_PATIENT_INDIVIDUAL_SAVED_BILL + "?patient_id=" + this.state.ipPatientData?.patient_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            IpPatientSavedBill: response.data.data
                        }, ()=>{ 
                            this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderTable = () => {
        const { t } = this.props
        return (
            <TableContainer component={'div'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Admission And Date")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NoOfBill")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.IpPatientSavedBill?.length > 0 ? this.state.IpPatientSavedBill.map((item, index) => (
                            <><TableRow
                                sx={{ backgroundColor: this.state.selectedIndex === index ? Colors?.ThemeLightColor : 'white', cursor: "pointer" }}
                                key={index}
                                onClick={() => {
                                    this.setState({
                                        selectedIndex: this.state.selectedIndex === index ? null : index
                                    })
                                }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === index ? null : index
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === index ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.ip_number ? item?.ip_number : "-"}
                                     | ${item?.admission_date ? item?.admission_date : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.no_of_bills ? item?.no_of_bills : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_bill_amount ? AmountFormat(item?.total_bill_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableOne(item, index)}
                                </TableRow></>
                        )) : <TableRow>
                            <TableCell />
                            <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    renderTableOne = (item, index) => {
        const { t } = this.props
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === index}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillNumber")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillType")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Module")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DoctorName")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' sx={{flexDirection: 'row', justifyContent: 'center', display: 'flex', visibility : (getCachevalue("isForCancelBill") === "true" || this.state.withOutAppointment?.is_ip) ? "hidden" : "visible"}} align='center'>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.bill_detail?.length > 0 ? item?.bill_detail.map((subItem, subIndex) => {
                                    let billType = subItem?.bill_type ? this.getCapitalize(subItem.bill_type) : "-";
                                    return (
                                        <TableRow key={subIndex}>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.invoice_date ? subItem.invoice_date : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.temp_bill_number ? subItem.temp_bill_number : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{billType ? billType : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} > {subItem?.module ? subItem.module : '-'}</Typography>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{
                                                        (subItem?.module === "PHARMACY" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(subItem?.pharmacy_name ? subItem?.pharmacy_name : "-", 10, "0.8rem") :
                                                            (subItem?.module === "LAB" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(subItem?.laboratory_name ? subItem?.laboratory_name : "-", 10, "0.8rem") : ""}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.doctor_name ? subItem.doctor_name : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{subItem?.amount_net ? AmountFormat(subItem.amount_net)?.replace(`${CurrencySymbol}`, "") : '0'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ visibility : getCachevalue("isForCancelBill") === "true" ? "hidden" : "visible"}}>
                                                <Button
                                                    disabled={CheckAccessFunc("front_office", "In Patients", "Service Billing", "Saved Bill", "SubTab")?.editAccess ? (((subItem?.module === "IP")  || (subItem?.module === "RADIOLOGY") || (subItem?.module === "LAB")) ? false : true) : true}
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        let { history } = this.props
                                                        this.setState({
                                                            ipSavedBillId: subItem?.id
                                                        }, () => {
                                                            if(subItem?.module === "IP"){
                                                                history.push({
                                                                    pathname: "/IPHome/PatientDetails/ServiceBilling",
                                                                    state: { AppointmentID: this.state.ipPatientData?.id, InvoiceID: this.state.ipSavedBillId, Reset:true }
                                                                })
                                                            }
                                                            if(subItem?.module === "RADIOLOGY"){
                                                                history?.push({
                                                                    pathname: '/Billing/RadiologyBilling', 
                                                                    state: { billSummaryId: this.state.ipSavedBillId, patientData:  subItem , SavedFromFO : true, BillFromIP: true} })
                                                            }
                                                            if(subItem?.module === "LAB"){
                                                                history.push({ pathname: "/Billing", state: { labPatientSavedBillData: subItem } })
                                                                setTimeout(() => {
                                                                    this.props.history.push({ pathname: "/Billing", state: { labPatientSavedBillData: {} } })
                                                                }, 2000)
                                                            }                                                              
                                                        })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={"Create Bill"} arrow><img
                                                        src={CheckAccessFunc("front_office", "In Patients", "Service Billing", "Saved Bill", "SubTab")?.editAccess ? ( ((subItem?.module === "IP")  || (subItem?.module === "RADIOLOGY") || (subItem?.module === "LAB")) ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default) : ImagePaths.DisabledCreateBill.default}
                                                        className='eMed_Pat_Bill_img'
                                                        alt='credit' /></Tooltip>
                                                </Button>
                                                <Button
                                                disabled={(!CheckAccessFunc("front_office", "In Patients", "Service Billing", "Saved Bill", "SubTab")?.editAccess)  || this.state.disable}
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        this.setState({
                                                            billDeleteClicked: true,
                                                            selectedDeleteID: subItem
                                                        })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={"Delete Bill"} arrow><img
                                                        src={CheckAccessFunc("front_office", "In Patients", "Service Billing", "Saved Bill", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} 
                                                        className='eMed_Pat_Bill_img'
                                                        alt='credit' /></Tooltip>
                                                </Button>
                                            </TableCell>
                                        </TableRow>)
                                }) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell />
                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>

                </Collapse>
            </TableCell>
            </>
        )
    }

    popupClose = () => {
    this.setState({
        billDeleteClicked: false,
        selectedDeleteID: {},
    })
    }
    
    onDeleteBill = () => {
        try{ 
            var Url = ""
            var data = {}
            let APIURL = ""
            let Module = this.state.selectedDeleteID?.module
            if (Module === "PHARMACY") {
                Url = Serviceurls.PHARMACY_SAVED_BILL_LIST + "?bill_summary_id=" + this.state.selectedDeleteID?.id + "&pharmacy_id=" + this.state.selectedDeleteID?.module_id
            } else if (Module === "LAB") {
                Url = Serviceurls.LAB_SAVED_BILL_DELETE + "?bill_summary_id=" + this.state.selectedDeleteID?.id + "&laboratory_id=" + this.state.selectedDeleteID?.module_id
            } else if(Module === "RADIOLOGY"){
                Url = Serviceurls.RAD_SAVED_BILL_GET + '?id=' + this.state.selectedDeleteID?.id
            } else {
                Url = Serviceurls.IP_PATIENT_INDIVIDUAL_SAVED_BILL
                data = { id: this.state.selectedDeleteID?.id }
            }
            this.setState({disable:true})
            RestAPIService.delete(Url, data)
            .then((response => {
            if (response.data.status === 'success') {
                this.setState({disable:false,billDeleteClicked:false})
                this.successMessage(response.data.message)
                this.getIpPatientSavedBillDetails()
                this.popupClose()
            }
            })).catch(e => {
            this.setState({disable:false}) 
            if (e?.data?.message) {
                this.errorMessage(e.data.message)
            } else {
                this.errorMessage(e.message)
            }
            })

        }catch (e) {
            this.setState({disable:false})
        this.errorMessage(e.message)
        }
    }

    render() {
        const { t } = this.props
        let serviceAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BillAmount"),
            lineTwo: this.state.service_amount ? AmountFormat(this.state.service_amount) : `${CurrencySymbol} 0`
        }
        let PaidDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Paid Amount"),
            lineTwo: this.state.paid_amount ? `${AmountFormat(this.state.paid_amount)}` : `${CurrencySymbol} 0`
        }

        let PendingDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BalAmt"),
            lineTwo: this.state.balance_amount ? AmountFormat(this.state.balance_amount) : `${CurrencySymbol} 0`,
            colorCode: this.state.balance_amount > 0 ? 'green' : this.state.balance_amount == 0 ? "balck" : 'red'
        }

        let ApproxDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('ApproxAmount'),
            lineTwo: this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount)}` : `${CurrencySymbol} 0`
        }

        let AdvanceDetails = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('AdvanceAmount'),
            lineTwo: this.state.advance_amount ? `${AmountFormat(this.state.advance_amount)}` : `${CurrencySymbol} 0`
        }
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        let ClaimDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            width: "1.3vw",
            height: "1.3vw",
            lineOne: t('Claimed Amount'),
            lineTwo: `${this.state.ApprovedAmt ? AmountFormat(this.state.ApprovedAmt) : `${CurrencySymbol} 0`}`
          }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: "74vh" }}>
                <Box component={'div'} className='' height={'9.5vh'}>
                    <Box component={'div'} >
                        <Box component={'div'} display={'flex'}>
                            <Box component={'div'} className='eMed_nur_notes_details eMed_bill_header_one'>
                                <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                            </Box>
                            {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                            <CommonPatientCard details={AdvanceDetails}/>
                            <CommonPatientCard details={serviceAmt} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={PaidDetails} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={PendingDetails} showOnecard={true} smallWidth={true}/>
                            <CommonPatientCard details={ApproxDetails} smallWidth={true}/>
                            <CommonPatientCard details={ClaimDetails} smallWidth={true} />
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} bgcolor={"white"} border={"1px solid lightgray"} margin={'0.2vw 0.8vw'} height={"64vh"} overflow={'scroll'}>
                        {this.renderTable()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.billDeleteClicked ?
                <DeletePopup
                DeletTitle={`${t("Are you sure ? Do you want to delete this Saved bill ?")}`}
                deleteAlertPopupClose={this.popupClose}
                removeData={this.onDeleteBill}
                ButtonText="Delete"
                BillType="Saved Bill"
                disable={this.state.disable}
                />
                : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

export default withTranslation()(IpPatientSavedBill)