import {
  Box, Button, Divider, Paper, Stack, Tooltip, Typography, Drawer, TextField,
  IconButton, Menu, MenuItem, InputAdornment, Table, TableHead, TableRow, TableCell,
  TableBody, Collapse, TablePagination, CircularProgress, Modal,
  FormControl,
  Select,
  InputLabel
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from '@mui/icons-material/Search';
import { Colors } from '../../../Styles/Colors';
import CancelIcon from '@mui/icons-material/Cancel';


let userAccess = null;
let RoleData = null;

let StatusList = [ "Placed Order", "Partially Delivered", "Completely Delivered", "Cancelled"]
class PharmaPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseOrderData: [],
      searchkey: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      search_PO_num: '',
      fromDate: '',
      toDate: '',
      dateIndex: 0,
      searchBillNo: '',
      tblPage: 0,
      tblPageSize: 10,
      openGrnList: false,
      grnPoDetails: [],
      poDetails: {},
      viewPageSize: 10,
      viewPage: 0,
      loading: false,
      serachPoFilter : "",
      cancelledPoId : null,
    }
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.history.location.state?.DirectPoSuccessMsg) {
      this.successMessage(this.props.history.location.state?.DirectPoSuccessMsg)
      this.props.history.push({ state: {} })
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  cancelPopclose() {
    this.setState({
      cancelledPoId: null
    })
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Purchase Order', null, "Tab");
    this.PurchaseOrderList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  PurchaseOrderList() {
    try {
      this.LoaderFunction(true);
      this.setState({ disableBtn: true, loading: true })
      RestAPIService.getAll(Serviceurls.PURCHASE_ORDER_LIST + "?search_term=" + (this.state.searchkey) + "&po_number=" + (this.state.search_PO_num) + "&page=" + (this.state.tblPage + 1) + "&page_size=" + this.state.tblPageSize + "&sort_by=" + 2 + '&from_date=' + this.state.fromDate + '&to_date=' + this.state.toDate + '&bill_no=' + this.state.searchBillNo + '&po_status=' + this.state.serachPoFilter).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              PurchaseOrderData: response.data.data?.result,
              rowCount: response.data.data?.total_count,
              disableBtn: false,
              loading: false
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
            this.setState({ disableBtn: false, loading: false })
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.setState({ disableBtn: false, loading: false })
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
      this.setState({ disableBtn: false, loading: false })
    }
  }

  printPurchaseOrder = () => {
    try {
      this.setState({ disableBtn: true })
      RestAPIService.getAll(`${Serviceurls.PURCHASE_ORDER_LIST}?export=${"pdf"}&search_term=${this.state.searchkey}&po_number=${this.state.search_PO_num}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&bill_no=${this.state.searchBillNo}&po_status=${this.state.serachPoFilter}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disableBtn: false })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  getPurchaseOrderExcel = () => {
    const { t } = this.props;
    try {
      this.setState({ disableBtn: true })
      RestAPIService.excelGet(`${Serviceurls.PURCHASE_ORDER_LIST}?export=${"excel"}&search_term=${this.state.searchkey}&po_number=${this.state.search_PO_num}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&bill_no=${this.state.searchBillNo}&po_status=${this.state.serachPoFilter}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({ disableBtn: false })
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  filterDate = (From, To, Index) => {
    this.setState({
      fromDate: From,
      toDate: To,
      dateIndex: Index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='purord_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' height={'42vh'}>
          <Box component={'div'} className='eMed_rts_dropdown'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.filterDate.bind(this)}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("VendorName")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              inputProps={{ emed_tid: 'VendorName' }}
              value={states.searchkey ? states.searchkey : ""}
              onChange={(e) => {
                this.setState({
                  searchkey: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("PO Number")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              inputProps={{ emed_tid: 'PoNUmber' }}
              value={states.search_PO_num ? states.search_PO_num : ""}
              onChange={(e) => {
                this.setState({
                  search_PO_num: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("Bill Number")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              inputProps={{ emed_tid: 'Billno' }}
              value={states.searchBillNo ? states.searchBillNo : ""}
              onChange={(e) => {
                this.setState({
                  searchBillNo: e.target.value?.trimStart()
                })
              }}
            />
          </Box>
          <Box>
            <FormControl size='small' className='eMed_rts_dropdown'>
              <InputLabel>{t('Status')}</InputLabel>
              <Select
                value={this.state.serachPoFilter}
                label={t('Status')}
                onChange={(event) => {
                  let values = event.target.value;
                  this.setState({
                    serachPoFilter: values
                  })
                }}
              >
                <MenuItem value={""}>{"All"}</MenuItem>
                {StatusList.map((item, index) => {
                  return <MenuItem key={index} value={item}>{item}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            emed_tid='fil_clear'
            disabled={this.state.disableBtn}
            onClick={() => {
              this.setState({
                searchkey: "",
                search_PO_num: "",
                fromDate: '',
                toDate: '',
                dateIndex: 0,
                searchBillNo: '',
                FilterOpen: false,
                serachPoFilter: "",
                cancelledPoId: null,
              }, () => this.PurchaseOrderList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            emed_tid='fil_search'
            onClick={() => {
              this.setState({ 
                FilterOpen: false,
                tblPage: 0,
              }, () => {
                this.PurchaseOrderList()
              })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  onFilterChange = (filterModel) => {
    if (filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0) {
      this.setState({
        searchkey: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues[0] : ""
      }, () => {
        this.PurchaseOrderList();
      })
    }
  }

  gridToolBar = () => {
    const { t } = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText searchHolder={t("searchVendorName")} />
      </Box>
    )
  }
  MoreMenus = (data) => {
    const { t } = this.props
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton emed_tid='grn_more_icon'
            disabled={data?.grn_status === "Completely Delivered" ? false : true}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data?.vendor_bill_id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow === data?.vendor_bill_id) ?
          <Menu elevation={1}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem emed_tid='grn_rtn_btn'
              onClick={() => {
                this.props.history.push(
                  {
                    pathname: "/PharmacyPurchase/PurchaseOrder/ReturnPO"
                    , state: {
                      purchase_order_id: data?.purchase_order_id,
                      purchase_status: data?.grn_status,
                      from: "PurchaseReturn",
                      vendor_bill_id: data?.vendor_bill_id
                    }
                  })
              }}>
              {'Return'}
            </MenuItem>
          </Menu>
          : null}
      </div>
    )
  }

  getPoDetailsData = (item) => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.PURCHASE_PO_DETAILS_GET + `?purchase_order_id=${item?.purchase_order_id}&vendor_bill_id=${item?.vendor_bill_id}`)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              grnPoDetails: response.data.data?.stock_items,
              poDetails: response.data.data,
              loading: false
            }, () => {
              let cgstAmount = 0; let BillAmount = 0;
              this.state.grnPoDetails?.forEach(element => {
                BillAmount = BillAmount + element?.total_buying_price
                cgstAmount = cgstAmount + element?.cgst_amount
              })
              this.state.poDetails.new_bill_amt = (BillAmount)?.toFixed(2)
              this.state.poDetails.new_cgst_amt = (cgstAmount)?.toFixed(2)
              this.state.poDetails.new_sgst_amt = (cgstAmount)?.toFixed(2)
              this.state.poDetails.new_taxable_amt = (BillAmount - cgstAmount * 2)?.toFixed(2)
              this.setState({ poDetails: this.state.poDetails })
            })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  CancelPO = () => {
    try {
      this.setState({ loading: true })
      let data = {
        "po_id" : this.state?.cancelledPoId
      }
      RestAPIService.create(data, Serviceurls.PHARMACY_PO_CANCEL)
        .then(response => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              cancelledPoId: null
            }, () => { this.PurchaseOrderList() })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  getGrnPrint = (item, list) => {
    try {
      RestAPIService.getAllPrint(Serviceurls.PURCHASE_DIRECT_PO_POST + `?purchase_order_number=${list?.po_number}&vendor_bill_id=${item?.vendor_bill_id}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewTable = () => {
    try {
      this.state.PurchaseOrderData?.forEach((element, index) => {
        let page = this.state.tblPage
        let pageSize = this.state.tblPageSize
        let serial = (page * pageSize) + (index + 1)
        element.serial = serial
      })
      return (
        <Box component={'div'} height={'69vh'}>
          <Box component={'div'} border={'1px solid lightgray'} height={'6vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} pr={'1vw'}>
            <TextField
              size='small'
              value={this.state.searchkey}
              onChange={(e) => this.setState({ searchkey: e.target.value }, () => this.PurchaseOrderList())}
              sx={{ width: '15vw' }}
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder='Search Vendor Name'
            />
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} height={'55vh'} overflow={'auto'}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"5%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>S.No</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>PI Date</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>PI Number</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Vendor Name</Typography>
                  </TableCell>
                  {/* <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Bill No.</Typography>
                  </TableCell> */}
                  <TableCell align='center' sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Total Amount</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>No. of GRN/Completed</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"13.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Count</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background }} width={"11.11%"}>
                    <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ? <TableRow><TableCell colSpan={9} sx={{ position: 'relative', top: '18vh', borderBottom: 'none' }} align='center'><CircularProgress color='primary' /></TableCell></TableRow> :
                  this.state.PurchaseOrderData?.length > 0 ? this.state.PurchaseOrderData?.map((item, index) => {
                    return (
                      <><TableRow>
                        <TableCell>{item?.serial}</TableCell>
                        <TableCell>{item?.purchase_order_date ? formatDate(item?.purchase_order_date) : '-'}</TableCell>
                        <TableCell>{item?.po_number ? item?.po_number : '-'}</TableCell>
                        <TableCell>{item?.vendor_name ? item?.vendor_name?.length > 17 ?
                          <Tooltip placement='top' title={item?.vendor_name}><Typography fontSize={'0.9vw'}>{item?.vendor_name?.slice(0, 17) + '...'}</Typography></Tooltip> :
                          item?.vendor_name : '-'}</TableCell>
                        {/* <TableCell>{item?.bill_no ? item?.bill_no : '-'}</TableCell> */}
                        <TableCell align='center'>{item?.total_amount ? item?.total_amount : '-'}</TableCell>
                        <TableCell align='center'>{`${item?.no_of_grn ? item?.no_of_grn : 0} / ${item?.deli_grn_count ? item?.deli_grn_count : 0}`}</TableCell>
                        <TableCell>
                          {/* {item?.status ? <Box component={'div'}
                            color={item?.status === 'Partially Delivered' ? '#FF474D' : item?.status === 'Placed Order' ? 'black' : '#00ab41'}>
                            {item?.status}</Box> : ''} */}
                          {`${item?.grn_items ? item?.grn_items : '0'} / ${item?.number_of_items ? item?.number_of_items : '0'}`}
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'} alignItems={'center'}>
                            {
                              item?.status === "Cancelled" ?
                                <Stack sx={{ padding: "0.3vw", borderRadius: "0.2vw", backgroundColor: "#FFE9E9" }}>
                                  <Typography sx={{ color: "#B82121" }}>Cancelled</Typography>
                                </Stack> :
                                <>
                                  <Tooltip placement='top' title="Receive Order">
                                    <Button className='Common_Btn_Min_Width' sx={{ width: '2vw', height: '2vw' }}
                                      onClick={() => {
                                        this.props.history.push(
                                          {
                                            pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO"
                                            , state: {
                                              purchase_order_id: item?.id, purchase_status: item?.status, purchaseDetail: item,
                                              from: "purchaseOrder"
                                            }
                                          })
                                      }}
                                    >
                                      <Box component={'img'} src={ImagePaths.deliveryBox.default} height={'3vw'} width={'3vw'} />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Cancel PO" arrow placement='top'>
                                    <IconButton
                                      emed_tid={'cancel_btn'}
                                      disabled={item?.status !== "Placed Order"}
                                      sx={{ visibility: userAccess?.viewAccess ? 'visible' : 'hidden' }}
                                      size='small'
                                      onClick={() => { this.setState({ cancelledPoId: item?.id }) }}
                                    >
                                      <CancelIcon color={ item?.status === "Placed Order" ? 'error' : 'disable'} />
                                    </IconButton>
                                  </Tooltip>
                                  <IconButton
                                    emed_tid={'arrow_btn'}
                                    sx={{ visibility: userAccess?.viewAccess ? 'visible' : 'hidden' }}
                                    size='small'
                                    onClick={() => {
                                      this.setState({
                                        tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                      })
                                    }}
                                  >
                                    {this.state.tableSelectedIndex === index ?
                                      <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </>
                            }

                          </Box>
                        </TableCell>
                      </TableRow>
                        <TableRow>
                          {this.renderSecondNewTable(item, index)}
                        </TableRow>
                      </>
                    )
                  }) :
                    <TableRow>
                      <TableCell align='center' colSpan={9}>No Records To Be Shown</TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </Box>
          <Box component={'div'}>
            <TablePagination
              className='eMed_tbl_pagination'
              sx={{ border: '1px solid lightgray' }}
              component='div'
              rowsPerPageOptions={[10, 20, 30]}
              count={this.state.rowCount}
              rowsPerPage={this.state.tblPageSize}
              page={this.state.tblPage}
              onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }, () => this.PurchaseOrderList()) }}
              onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }, () => this.PurchaseOrderList()) }}
            />
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderSecondNewTable = (item, index) => {
    try {
      return (
        <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
            <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Bill Date</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>GRN Number</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Bill Number</Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Bill Amount</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Delivery Date</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Status</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.grn_details?.length > 0 ? item?.grn_details?.map((list, listIndex) => {
                    return (
                      <TableRow key={listIndex}>
                        <TableCell>{list?.grn_bill_date ? formatDate(list?.grn_bill_date?.slice(0, 10)) : '-'}</TableCell>
                        <TableCell>{list?.grn_number ? list?.grn_number : '-'}</TableCell>
                        <TableCell>{list?.grn_bill_no ? list?.grn_bill_no : '-'}</TableCell>
                        <TableCell align='right'>{list?.grn_bill_amt ? list?.grn_bill_amt : '-'}</TableCell>
                        <TableCell>{list?.delivery_dt ? formatDate(list?.delivery_dt?.slice(0, 10)) : '-'}</TableCell>
                        <TableCell>{list?.grn_status ? list?.grn_status : '-'}</TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'}>
                            {/* <Tooltip placement='top' title='View'>
                              <Button className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openGrnList: true, grnId: list?.id }, () => this.getPoDetailsData(list))}
                              >
                                <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} />
                              </Button>
                            </Tooltip> */}
                            <Tooltip placement='top' title='View'>
                              <Button className='Common_Btn_Min_Width' emed_tid='grn_view_btn'
                                onClick={() => {
                                  this.props.history.push(
                                    {
                                      pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO"
                                      , state: {
                                        purchase_order_id: item?.id, purchase_status: list?.grn_status, purchaseDetail: item,
                                        from: "purchaseOrder",
                                        fromGrn: true,
                                        vendor_bill_id: list?.vendor_bill_id
                                      }
                                    })
                                }}
                              >
                                <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} />
                              </Button>
                            </Tooltip>
                            <Tooltip placement='top' title='Print'>
                              <Button className='Common_Btn_Min_Width' emed_tid='grn_prt_btn'
                                onClick={() => this.getGrnPrint(list, item)}
                              >
                                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
                              </Button>
                            </Tooltip>
                            {this.MoreMenus(list)}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  }) : <TableRow>
                    <TableCell align='center' colSpan={7}>No Records To Be Shown</TableCell>
                  </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell></>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderGrnViewPopup = () => {
    try {
      this.state.grnPoDetails?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        { field: 'sno', headerName: 'S.No', flex: 0.05 },
        {
          field: 'brand_name', headerName: 'Product Details', flex: 0.236,
          renderCell: (params) => {
            let brandName = params?.row?.brand_name
            let genericName = params?.row?.generic_name
            let dosage = `${params?.row?.dosage_type} | ${params?.row?.dosage_strength}`
            let hsnCode = params?.row?.hsn_code
            return (
              <Box component={'div'}>
                {brandName ? brandName?.length > 30 ?
                  <Tooltip placement='top' title={brandName}><Typography fontSize={'0.8vw'} fontWeight={600}>{brandName?.slice(0, 30) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'} fontWeight={600}>{brandName}</Typography> : null}
                {genericName ? genericName?.length > 30 ?
                  <Tooltip placement='top' title={genericName}><Typography fontSize={'0.8vw'}>{genericName?.slice(0, 30) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{genericName}</Typography> : null}
                {dosage ? dosage?.length > 30 ?
                  <Tooltip placement='top' title={dosage}><Typography fontSize={'0.8vw'}>{dosage?.slice(0, 30) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{dosage}</Typography> : null}
                {hsnCode ? hsnCode?.length > 30 ?
                  <Tooltip placement='top' title={hsnCode}><Typography fontSize={'0.8vw'}>{hsnCode?.slice(0, 30) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{hsnCode}</Typography> : null}
              </Box>
            )
          }
        },
        {
          field: 'buying_price', headerName: 'Buying Price/Unit', flex: 0.143,
          renderCell: (params) => {
            let buyingPrice = params?.row?.buying_price
            return (
              <Box component={'div'}>
                <Typography fontSize={'0.8vw'}>{buyingPrice ? buyingPrice : '-'}</Typography>
              </Box>
            )
          }
        },
        {
          field: 'mrp_per_quantity', headerName: 'MRP', flex: 0.143,
          renderCell: (params) => {
            let mrpPrice = params?.row?.mrp_per_quantity
            return (
              <Box component={'div'}>
                <Typography fontSize={'0.8vw'}>{mrpPrice ? mrpPrice : '-'}</Typography>
              </Box>
            )
          }
        },
        {
          field: 'quantity', headerName: 'Quantity', flex: 0.143,
          renderCell: (params) => {
            let purchaseQty = params?.row?.sales_unit * params?.row?.pack_of_drugs
            let totalQty = (params?.row?.pack_of_drugs && params?.row?.sales_unit && !params?.row?.is_free_quantity) ?
              ((params?.row?.sales_unit * params?.row?.pack_of_drugs) + (params?.row?.free_quantity * params?.row?.pack_of_drugs)) :
              ((params?.row?.free_quantity * params?.row?.pack_of_drugs))
            let freeQty = params?.row?.free_quantity && params?.row?.pack_of_drugs ? params?.row?.free_quantity * params?.row?.pack_of_drugs : params?.row?.free_quantity
            let batchNo = `Batch No.: ${params?.row?.batch_no}`
            let ExpDate = `Exp. Date: ${params?.row?.expiry_date}`
            let freeDrug = `${freeQty} (Free)`
            let drugQty = `${totalQty} / ${params?.row?.is_free_quantity ? 0 : purchaseQty} + ${freeDrug}`
            return (
              <Box component={'div'}>
                {drugQty ? drugQty?.length > 15 ?
                  <Tooltip placement='top' title={drugQty}><Typography fontSize={'0.8vw'}>{drugQty?.slice(0, 15) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{drugQty}</Typography> : null}
                {batchNo ? batchNo?.length > 20 ?
                  <Tooltip placement='top' title={batchNo}><Typography fontSize={'0.8vw'}>{batchNo?.slice(0, 20) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{batchNo}</Typography> : null}
                {ExpDate ? ExpDate?.length > 22 ?
                  <Tooltip placement='top' title={ExpDate}><Typography fontSize={'0.8vw'}>{ExpDate?.slice(0, 22) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{ExpDate}</Typography> : null}
              </Box>
            )
          }
        },
        {
          field: 'purchase_value', headerName: 'Purchase Value', flex: 0.143,
          renderCell: (params) => {
            let gstAmount = params?.row?.cgst_amount * 2
            let purchaseValue = `${(params?.row?.total_buying_price - gstAmount)?.toFixed(2)} + ${params?.row?.cgst_percentage * 2}% GST`
            return (
              <Box component={'div'}>
                {purchaseValue ? purchaseValue?.length > 20 ?
                  <Tooltip placement='top' title={purchaseValue}><Typography fontSize={'0.8vw'}>{purchaseValue?.slice(0, 20) + '...'}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'}>{purchaseValue}</Typography> : null}
              </Box>
            )
          }
        },
        {
          field: 'net_amount', headerName: 'Net Amount', flex: 0.143,
          renderCell: (params) => {
            let netAmount = params?.row?.total_buying_price
            return (
              <Box component={'div'}>
                <Typography fontSize={'0.8vw'}>{netAmount}</Typography>
              </Box>
            )
          }
        },
      ]
      let states = this.state
      return (
        <Box component={'div'}>
          <Modal open={this.state.openGrnList}>
            <Box component={'div'} className='eMed_PO_Popup'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} p={'0.5vw'}>
                <Typography fontWeight={'600'}>GRN Details</Typography>
                <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openGrnList: false, viewPage: 0, viewPageSize: 10, grnPoDetails: [] })}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} p={'0.5vw'}>
                <Box component={'div'} width={'20vw'}>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.3} color={Colors.grayShade}>{`Vendor Name`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.7}>{`: ${states.poDetails?.vendor_name ? states.poDetails?.vendor_name : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.3} color={Colors.grayShade}>{`PO Number`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.7}>{`: ${states.poDetails?.purchase_order_number ? states.poDetails?.purchase_order_number : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.3} color={Colors.grayShade}>{`Bill Date`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.7}>{`: ${states.poDetails?.latest_vendor_bill?.bill_date ? formatDate(states.poDetails?.latest_vendor_bill?.bill_date) : '-'}`}</Typography>
                  </Box>
                </Box>
                <Box component={'div'} width={'20vw'}>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`GRN Number`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.grn_no ? states.poDetails?.grn_no : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`Bill Number`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.latest_vendor_bill?.bill_no ? states.poDetails?.latest_vendor_bill?.bill_no : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`Delivery Date`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.latest_vendor_bill?.delivery_date ? formatDate(states.poDetails?.latest_vendor_bill?.delivery_date) : '-'}`}</Typography>
                  </Box>
                </Box>
                <Box component={'div'} width={'20vw'}>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`Taxable Amount`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.new_taxable_amt ? states.poDetails?.new_taxable_amt : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`CGST Amount`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.new_cgst_amt ? +states.poDetails?.new_cgst_amt : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`SGST Amount`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.new_sgst_amt ? +states.poDetails?.new_sgst_amt : '-'}`}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.8vw'} fontWeight={'600'} flex={0.35} color={Colors.grayShade}>{`Bill Amount`}</Typography>
                    <Typography component={'span'} fontSize={'0.8vw'} fontWeight={'600'} flex={0.65}>{`: ${states.poDetails?.new_bill_amt ? +states.poDetails?.new_bill_amt : '-'}`}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} height={'30vw'} p={'0.5vw'}>
                <DataGrid
                  rows={this.state.grnPoDetails}
                  columns={columns}
                  getRowId={(row) => row?.sno}
                  page={this.state.viewPage}
                  pageSize={this.state.viewPageSize}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  components={{
                    // Toolbar: this.gridToolBar,
                    NoRowsOverlay: () => (
                      <Box className='eMed_conf_nodata'>
                        {("NoRecordsFound")}
                      </Box>
                    )
                  }}
                  onPageChange={(newPage) => { this.setState({ viewPage: newPage }) }}
                  onPageSizeChange={(newPageSize) => this.setState({ viewPageSize: newPageSize })}
                  rowsPerPageOptions={[10, 20, 30]}
                  pagination
                  headerHeight={40}
                  disableSelectionOnClick
                  loading={this.state.loading}
                  rowHeight={90}
                />
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  // renderTable = () => {
  //   this.state.PurchaseOrderData.forEach((element, index) => { 
  //     let page = this.state.page 
  //     let pageSize = this.state.pageSize
  //     let serial = (page * pageSize) + (index + 1) 
  //     element.serial = serial
  //     element.taxable_amount = (element.total_amount - (element.cgst_amount + element.sgst_amount))?.toFixed(2)
  //    })
  //   const { t } = this.props
  //   const columns = [
  //     {
  //       field: "serial", flex: 0.05, headerName: t("SNo"), headerAlign: "center", align: "center",
  //       renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
  //     },
  //     // {
  //     //   field: "purchase_order_date", flex: 0.083, headerName: t("PO Date"), headerAlign: "center", align: "center",
  //     //   renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.purchase_order_date ? formatDate(params?.row?.purchase_order_date) : "-"}</Box>)
  //     // },
  //     {
  //       field: "po_number", flex: 0.083, headerName: t("PO Number"),
  //       renderCell: (params) => (
  //         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
  //           <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.po_number ? params?.row?.po_number : "-"}</Typography>
  //           <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8vw"} >{params?.row?.purchase_order_date ? formatDate(params?.row?.purchase_order_date) : "-"}</Typography>
  //         </Box>
  //       // <Box component={'div'}>{params?.row?.po_number ? params?.row?.po_number : "-"}</Box>
  //       )
  //     },
  //     {
  //       field: "vendor_name", flex: 0.116, headerName: t("Vendor Name"),
  //       renderCell: ({ row }) => (<div>{row?.vendor_name?.length > 20 ?
  //         <Tooltip placement="top" title={row?.vendor_name} arrow><div>{row?.vendor_name.slice(0, 20) + "..."}</div></Tooltip>
  //         : row?.vendor_name ? row?.vendor_name : "-"}</div>)
  //     },
  //     {
  //       field: "bill_no", flex: 0.083, headerName: t("Bill No"), headerAlign: "center", align: "center",
  //       renderCell: ({ row }) => (<div>{row?.bill_no?.length > 10 ?
  //         <Tooltip placement="top" title={row?.bill_no} arrow><div>{row?.bill_no.slice(0, 10) + "..."}</div></Tooltip>
  //         : row?.bill_no ? row?.bill_no : "-"}</div>)
  //     },
  //     {
  //       field: "total_amount", flex: 0.083, headerName: t("Bill Amount"), type: 'number',
  //       renderCell: ({ row }) => (<div>{row?.total_amount ? row?.total_amount : "-"}</div>)
  //     },
  //     {
  //       field: "taxable_amount", flex: 0.083, headerName: t("Taxable Amount"), type: 'number',
  //       renderCell: ({ row }) => (<div>{row?.taxable_amount ? row?.taxable_amount : "-"}</div>)
  //     },
  //     {
  //       field: "number_of_items", flex: 0.083, headerName: t("Number of Items"), type: "number",
  //       renderCell: (params) => (<Box component={'div'}>{params?.row?.number_of_items ? params?.row?.number_of_items : "-"}</Box>)
  //     },
  //     // { key not available
  //     //   field: "mrp_per_quantity", flex: 0.1, headerName: t("GRN"), headerAlign: "center", align: "center",
  //     //   renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
  //     // },
  //     {
  //       field: "delivery_date", flex: 0.083, headerName: t("Delivery Date"), headerAlign: "center", align: "center",
  //       renderCell: (params) => (<Box component={'div'}>{params?.row?.delivery_date ? formatDate(params?.row?.delivery_date) : "-"}</Box>)
  //     },
  //     {
  //       field: "entered_by", flex: 0.083, headerName: t("Entered By"), headerAlign: "center", align: "center",
  //       renderCell: (params) => (<div>
  //         {params?.row?.entered_by?.length > 12 ?
  //           <Tooltip placement="top" title={params?.row?.entered_by} arrow><div>{params?.row?.entered_by.slice(0, 12) + "..."}</div></Tooltip>
  //           : params?.row?.entered_by ? params?.row?.entered_by : "-"}
  //       </div>)
  //     },
  //     {
  //       field: "grn_number", flex: 0.083, headerName: t("GRN No"), headerAlign: "center", align: "center",
  //       renderCell: (params) => (
  //         <div>
  //           {params?.row?.grn_number?.length > 12 ?
  //             <Tooltip placement="top" title={params?.row?.grn_number} arrow><div>{params?.row?.grn_number.slice(0, 12) + "..."}</div></Tooltip>
  //             : params?.row?.grn_number ? params?.row?.grn_number : "-"}
  //         </div>
  //       )
  //     },
  //     {
  //       field: "status", flex: 0.083, headerName: t("Status"), headerAlign: "center", align: "center",
  //       renderCell: ({ row }) => {
  //         let purchase_status = row?.status === "Completely Delivered" ? "Delivered" : row?.status === "Partially Delivered" ? "Partially Delivered" : "Place order"
  //         let divColor = row?.status === "Completely Delivered" ? "#ECFFE9" : row?.status === "Partially Delivered" ? "#FFE9E9" : null;
  //         let txtColor = row?.status === "Completely Delivered" ? "#1E972A" : row?.status === "Partially Delivered" ? "#B82121" : null;
  //         return (
  //           <Stack sx={{ padding: "0.4vw", borderRadius: "0.5vw", backgroundColor: divColor }}>
  //             <Typography sx={{ color: txtColor ,fontSize:'0.9vw'}}>{purchase_status ? purchase_status : "-"}</Typography>
  //           </Stack>
  //         );
  //        }
  //     }
  //   ]

  //   if (userAccess?.viewAccess) {
  //     columns.push(
  //       {
  //         field: "action", flex: 0.083, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
  //         renderCell: ({ row }) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
  //           <Tooltip title={t("Receive Order")} placement="top" arrow>
  //             <Button emed_tid='purord_movedrt' className='eMed_usrconf_btn' onClick={() => {
  //               this.props.history.push(
  //                 {
  //                   pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO"
  //                   , state: {
  //                     purchase_order_id: row?.id, purchase_status: row?.status,purchaseDetail:row,
  //                     from: "purchaseOrder"
  //                   }
  //                 })
  //             }}>
  //               <img src={ImagePaths.deliveryBox.default} alt="Box" className='eMed_pur_deliv_img' />
  //             </Button></Tooltip>
  //             {this.MoreMenus(row)}
  //         </Box>
  //       }
  //     );
  //   }

  //   return (
  //     <Box component={"div"} className='eMed_Purcahse_Table_Container'>
  //       <Stack component={"div"}>
  //         <Paper className='eMed_Purchase_Table_Wrapper' >
  //           <DataGrid
  //             rows={this.state.PurchaseOrderData}
  //             columns={columns}
  //             getRowId={(row) => row['serial']}
  //             page={this.state.page}
  //             pageSize={this.state.pageSize}
  //             hideFooterSelectedRowCount
  //             localeText={{
  //               toolbarColumns: "",
  //               toolbarDensity: "",
  //               toolbarExport: "",
  //               toolbarFilters: "",
  //               toolbarExportPrint: ""
  //             }}
  //             headerHeight={40}
  //             components={{
  //               Toolbar: this.gridToolBar,
  //               NoRowsOverlay: () => (
  //                 <Stack className='eMed_conf_nodata'>
  //                   {t("NoRecordsFound")}
  //                 </Stack>
  //               )
  //             }}
  //             initialState={{
  //               columns: {
  //                   columnVisibilityModel: {
  //                     number_of_items: false
  //                   }
  //               }
  //           }}
  //             rowsPerPageOptions={[10, 20, 30]}
  //             disableSelectionOnClick
  //             pagination
  //             paginationMode='server'
  //             onPageChange={(newPage) => { this.setState({ page: newPage }, () => { this.PurchaseOrderList() }) }}
  //             onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => { this.PurchaseOrderList() })}
  //             loading={this.state.isLoader}
  //             rowCount={this.state.rowCount}
  //             filterMode="server"
  //             onFilterModelChange={this.onFilterChange}
  //           />
  //         </Paper>
  //       </Stack>
  //     </Box>
  //   )
  // }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className='eMed_Purchase_Table_top'>
          {/* <Button emed_tid='drt_po_req' className='eMed_Filter_Btns' variant='contained' size='small'
            onClick={() => {
              this.props.history.push({ pathname: "/PharmacyPurchase/PurchaseOrder/POrequest" })
            }}
          >{`Purchase Request`}</Button> */}
          {
            RoleData?.permission_access?.pharma?.isShowPo || !RoleData?.is_user ?
              <><Button emed_tid='drt_po' className='eMed_Filter_Btns' variant='contained' size='small'
                onClick={() => {
                  this.props.history.push({ pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO", state: { fromDirectPO: true ,fromGrn:false} })
                }}
              >{`GRN Entry`}</Button>
                <Divider orientation='vertical' /></>
              : null
          }
          <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid='purord_filter' className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip>
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid='purord_print' className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.printPurchaseOrder()}>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid='purord_export' className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn} onClick={() => this.getPurchaseOrderExcel()}>
              <img src={ImagePaths.LabUploadButton.default} alt='upload' className='eMed_action_img' />
            </Button></Tooltip>
        </div>
        {/* {this.renderTable()} */}
        {this.renderNewTable()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.cancelledPoId ? <DeletePopup
          DeletTitle={`Are you sure you want to cancel this PO ?`}
          deleteAlertPopupClose={this.cancelPopclose.bind(this)}
          removeData={this.CancelPO.bind(this)}
          disable={this.state.removeData}
          ButtonText={"Cancel PO"}
        /> : null}
        {this.renderGrnViewPopup()}
      </div>
    )
  }
}
export default withTranslation()(PharmaPurchaseOrder)
