import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import {
  Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Table,
  TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import ShowComponents from '../Common/ShowComponent';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { language } from '../../../../Utility/Constants';


class DocPrescriptionLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: localGetItem('SelectedDoctorClinicId'),
      prescriptionData: [],
      isDeleteCliked: false,
      SelectedList: {},
      PatientData: this.props?.PatientData,
      prevPrescripData: [],
      previousPrescripDate: [],
      prescriptionFav: [],
      prescriptionTemplateList: [],
      dupliPrescriptionData: "[]",
      selectedTemplate: "",
      SelectedEditIndex: null,
      languageList: language,
      selectedLanguage: 'english',
      Recalled: true,
      recallPopup: false,
      is_pharmacy: false
    }
  }

  componentDidMount() {
    // if (this.props.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) {
    //     this.setState({ withOutAppointment: this.props.withOutAppointment })
    // }
    let hasPharmacy = JSON.parse(localGetItem('loggedInUserInfo'))?.pharmacy_data
    this.setState({ is_pharmacy: hasPharmacy?.length === 0 ? true : false })
  }
  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetIntialData() }
    if (prevprops.PatientData !== this.props.PatientData) { this.setState({ PatientData: this.props.PatientData }, () => { this.GetIntialData() }) }
    if (prevprops.ClearClickedonRightPrecs !== this.props.ClearClickedonRightPrecs && this.props?.ClearClickedonRightPrecs) {
      this.setState({
        SelectedEditIndex: null
      }, () => { this.props.ClearPropsData() })
    }
    if (prevprops.AddPrescritionItem !== this.props.AddPrescritionItem && this.props.AddPrescritionItem?.length > 0) {
      if (this.state.SelectedEditIndex === null) {
        let Array = [...this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : [], ...this.props.AddPrescritionItem]
        this.state.prescriptionData.prescription_lines = Array
        this.setState({
          prescriptionData: this.state.prescriptionData,
          selectedPharmacy: Array?.length > 0 && Array[0]?.pharmacy_id ? Array[0]?.pharmacy_id : "",
        },
          () => { this.props.getPrescriptionData(Array) })
      } else {
        this.state.prescriptionData.prescription_lines[this.state.SelectedEditIndex] = this.props.AddPrescritionItem[0]
        this.setState({
          prescriptionData: this.state.prescriptionData, SelectedEditIndex: null,
          selectedPharmacy: (this.state.prescriptionData.prescription_lines?.length > 0 && this.state.prescriptionData.prescription_lines[0]?.pharmacy_id) ?
            this.state.prescriptionData.prescription_lines[0]?.pharmacy_id : "",
        },
          () => { this.props.getPrescriptionData(this.state.prescriptionData?.prescription_lines || []) })
      }
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }



  GetIntialData = () => {
    this.GetPrescriptionData()
    this.getPrescriptionFavouriteData()
    this.getPrescriptionListData()
    this.getPharmacyList()
  }

  postPrescriptionTransmit = (recalled) => {
    try {
      let data = {}
      if (recalled) {
        data = {
          appointment_id: this.state.PatientData?.appointment_id,
          prescription_id: this.state.prescriptionData?.prescription_id,
          is_recall: true,
          patient_id: this.state.PatientData?.patient_id
        }
      } else {
        data = {
          appointment_id: this.state.PatientData?.appointment_id,
          patient_id: this.state.PatientData?.patient_id,
          pharmacy_id: this.state?.selectedTransmitPharmacy ? this.state?.selectedTransmitPharmacy : this.state.selectedPharmacy,
          prescription_id: this.state.prescriptionData?.prescription_id
        }
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TRANSMIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ openTransmit: false, selectedTransmitPharmacy: '', transmitted: true, recallPopup: false }, () => {
              this.GetPrescriptionData()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyList(prevPrescrip = false, formTransmit = false) {
    try {
      // RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PHARMACY_LIST_GET + `?clinic_id=${this.state.clinicId}&suggest=true`)
      //   .then((response) => {
      //     if (response.data.status === "success") {
      //       this.setState({
      //         pharmacyList: response.data?.data,
      //         selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //       }, () => {
      //         if (formTransmit) {
      //           if (response.data?.data?.length > 1) {
      //             this.setState({
      //               openTransmit: true
      //             })
      //           } else {
      //             this.postPrescriptionTransmit()
      //           }
      //         }
      //       })
      //     }
      //     else {
      //       this.errorMessage(response.data.message)
      //     }
      //   }).catch((error) => {
      //     this.errorMessage(error.message)
      //   })
      let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
      let pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
      if (pharmaList?.length) {
        this.setState({
          pharmacyList: pharmaList,
          selectedPharmacy: pharmaList[0]?.pharmacy_id || ''
        })
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  postTemplate = () => {
    try {
      let data = {}
      if (this.state.tempType === 'Add') {
        let prescriptionList = []
        this.state.prescriptionData?.prescription_lines?.forEach(element => {
          prescriptionList?.push({
            afternoon: +element?.afternoon,
            dosage_strength: element?.dosage_strength,
            dosage_type: element?.dosage_type,
            drug_name: element?.brand_name,
            duration: element?.duration,
            duration_mode: element?.duration_mode,
            evening: +element?.evening,
            generic_name: element?.generic_name,
            instructions: element?.instructions,
            morning: +element?.morning,
            night: +element?.night,
            is_unit_ml: element?.is_unit_ml ? element?.is_unit_ml : false,
            pharmacy_id: element?.pharmacy_id ? element?.pharmacy_id : null,
            is_unit_others: element?.is_unit_others ? element?.is_unit_others : false,
            is_tapper_meds: element?.tapper_meds?.length === 0 ? false : true,
            tapper_meds: element?.tapper_meds ? element?.tapper_meds : [],
            is_external_drug: element?.is_external_drug ? element?.is_external_drug : false
          })
        })
        data = {
          pharmacy_id: this.state.selectedPharmacy,
          template_name: this.state.templateName,
          prescription: prescriptionList
        }
      } else if (this.state.tempType === 'rename') {
        data = {
          template_name: this.state.templateName,
          template_id: this.state.selectedTemplate?.id
        }
      }
      if (this.state.templateName === '') {
        this.errorMessage('Enter Template Name')
      } else {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getPrescriptionListData()
              this.setState({ tempType: '', openPopupAddNew: false, selectedTemplate: '', templateName: '' })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  removeKey = (data, pharmacy_id) => {
    const newArray = data.map((obj) => {
      const { ['id']: removedKey, ...newObj } = obj;
      return { ...newObj, pharmacy_id };
    });
    return newArray
  };

  GetPrescriptionData() {
    try {
      let params = ''
      if (this.state.selectedTemplate) {
        params = Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE + `?template_id=${this.state.selectedTemplate?.id}`
      } else {
        params = Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION + `?patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.state.PatientData?.appointment_id}`
      }
      RestAPIService.getAll(params).
        then((response) => {
          if (response.data.status === "success") {
            let tempPharmaId = response.data.pharmacy_id
            let pharmaId = this.state.prescriptionData?.prescription_lines?.find(item => item?.pharmacy_id === this.state.selectedPharmacy)?.pharmacy_id

            if (this.state.selectedTemplate) {
              if ((tempPharmaId === pharmaId) || (this.state.prescriptionData?.prescription_lines?.length === 0 || this.state.prescriptionData?.length === 0)) {
                var NewArray = this.removeKey(response.data.data, response.data.pharmacy_id)
                let Array = [...this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : [], ...NewArray ? NewArray : []]
                this.state.prescriptionData.prescription_lines = Array
                this.setState({
                  prescriptionData: this.state.prescriptionData,
                  selectedTemplate: "",
                  selectedPharmacy: (Array?.length > 0 && Array[0]?.pharmacy_id) ? Array[0]?.pharmacy_id : "",
                }, () => {
                  this.props.getPrescriptionData(Array)
                })
              } else {
                this.errorMessage('Selected Template is not matched with selected pharmacy')
              }
            }
            else {
              this.setState({ showAddTemplate: response.data.data?.length > 0 ? true : false })
              const Data = response?.data?.data[0] || [];
              this.setState({
                prescriptionData: Data ? Data : [],
                transmitted: Data?.is_transmitted ? Data?.is_transmitted : false,
                dupliPrescriptionData: JSON.stringify(Data),
                Recalled: Data?.is_billed_in_pharmacy,
                selectedPharmacy: (Data?.prescription_lines?.length > 0 && Data?.prescription_lines[0]?.pharmacy_id) ? Data?.prescription_lines[0]?.pharmacy_id : "",
              }, () => {
                if (Data?.is_transmitted) {
                  this.props.handleDisableTransfer(true)
                } else if (Data?.is_transmitted === false) {
                  this.props.handleDisableTransfer(false)
                }
                this.props.getPrescriptionData(Data?.prescription_lines || [])
              })
            }
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getPrescriptionListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionTemplateList: response?.data?.data || []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler(data, index) {
    this.setState({
      SelectedEditIndex: index
    }, () => { this.props.EditPrescriptionData(data) })
  }

  onDeleteHandler(data, index) {
    try {
      this.state.prescriptionData?.prescription_lines?.splice(index, 1)
      this.setState({
        prescriptionData: this.state.prescriptionData,
        selectedPharmacy: (this.state.prescriptionData?.prescription_lines?.length > 0 && this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id) ?
          this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id : "",
      }, () => {
        this.setState({showAddTemplate:this.state.prescriptionData?.length > 0 ? true : false})
        this.props.getPrescriptionData(this.state.prescriptionData?.prescription_lines || [])
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }

  removedPrescription = () => {
    try {
      let removedIds = []; let prescriptionHash = {};
      let duplicate = JSON.parse(this.state.dupliPrescriptionData)
      this.state.prescriptionData?.prescription_lines?.forEach(element => {
        prescriptionHash[element.id] = element
      })

      duplicate?.prescription_lines?.forEach(element => {
        if (!prescriptionHash[element.id]) {
          removedIds.push(element.id)
        }
      })

      return removedIds;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PostPrescriptionData(fromFav = false, fromTemplate = true) {
    try {
      var states = this.state
      let removedIds = this.state.dupliPrescriptionData ? this.removedPrescription() : []
      let date = this.state.selectedFollowup ? new Date(this.state.selectedFollowup) : null
      let followUpDate = this.state.selectedFollowup ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : null
      let data = {
        appointment_id: this.state.PatientData?.appointment_id,
        deleted_line_ids: removedIds,
        followup_date: this.state.selectedFollowup ? followUpDate : null,
        patient_id: this.state.PatientData?.patient_id,
      }
      if (this.state.prescriptionData?.prescription_id) {
        data.prescription_id = this.state.prescriptionData?.prescription_id
      } else {
        data.pharmacy_id = this.state.selectedPharmacy
      }
      if (this.state.selectedPrescripFav?.length > 0 && fromFav) {
        data.line_items = this.state.selectedPrescripFav
      }
      else if (this.state.selectedTemplate && fromTemplate) {
        let prescriptionData = []
        this.state.prescriptionData?.prescription_lines?.forEach(element => {
          prescriptionData?.push({
            afternoon: element?.afternoon ? (element?.afternoon)?.toString() : '0',
            brand_name: element?.brand_name ? element?.brand_name : '',
            comments: element?.comments ? element?.comments : '',
            dosage_strength: element.dosage_strength ? element.dosage_strength : '',
            dosage_type: element.dosage_type ? element.dosage_type : '',
            drug_id: element.drug_id ? element.drug_id : '',
            duration: element.duration ? element.duration : '',
            duration_mode: element.duration_mode ? element.duration_mode : '1',
            evening: element.evening ? (element.evening).toString() : '0',
            generic_name: element.generic_name ? element.generic_name : '',
            instructions: element.instructions ? element.instructions : '',
            is_favorite: element.is_favorite,
            morning: element.morning ? (element.morning.toString()) : '0',
            night: element.night ? (element.night).toString() : '0',
            is_unit_ml: element?.is_unit_ml ? element?.is_unit_ml : false,
            tapper_meds: element?.tapper_meds ? element?.tapper_meds : [],
            is_tapper_meds: element?.tapper_meds?.length === 0 ? false : true,
            is_unit_others: element?.is_unit_others ? element?.is_unit_others : false,
            is_external_drug: element?.is_external_drug ? element?.is_external_drug : false
          })
        })
        data.line_items = prescriptionData
      } else {
        this.state.prescriptionData?.prescription_lines?.forEach(element => {
          element.is_tapper_meds = element?.tapper_meds?.length === 0 ? false : true
          element.is_external_drug = element?.is_external_drug ? element?.is_external_drug : false
        })
        data.line_items = this.state.prescriptionData?.prescription_lines
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => {
              this.GetPrescriptionData()
              this.getPrescriptionFavouriteData()
            })
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES + `?id=${states.SelectedList?.id}`, {}).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetPrescriptionData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderRecallPopup = () => {
    return (
      <Dialog
        open={this.state.recallPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5vw', marginBottom: '0vw' }}>
          <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.setState({ recallPopup: false }) }} />
        </div>
        <DialogTitle id="alert-dialog-title">
          {`Are you sure ? Do you want to Edit Prescription`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '0.8vw' }} id="alert-dialog-description">
            {""}
          </DialogContentText>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-end" }}>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button size="small" variant="outlined" onClick={() => { this.setState({ recallPopup: false }) }}>
              {"CLOSE"}
            </Button>
            <Button size="small" variant="contained" onClick={() => { this.postPrescriptionTransmit(true) }}>
              {"RECALL"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }

  instructions = (item) => {
    let value = '';
    switch (item) {
      case 'BF':
        value = 'Before Food'
        break;
      case 'AF':
        value = 'After Food'
        break;
      case 'EU':
        value = 'External Use'
        break;
      case 'ES':
        value = 'Empty Stomach'
        break;
      case 'WF':
        value = 'With Food'
        break;
      default:
        value = item;
        break;
    }
    return value;
  }


  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getPreviousPrescriptionDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.state.selectedPrevDate?.appointment}&date=${this.state.selectedPrevDate?.prescription_date}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prevPrescripData: response.data.prescription_lines
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FAV)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionFav: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionDates = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT_DATE_GET + `?patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.props.PatientData?.appointment_id}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              openPrevious: true,
              previousPrescripDate: response.data.prescription,
              selectedPrevDate: response?.data?.prescription[0],
              selectedPrevAppointmentId: response?.data?.prescription[0]?.appointment
            }, () => {
              if (this.state.previousPrescripDate?.length > 0) {
                this.getPreviousPrescriptionDatas()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  copyPreviousPrescription = () => {
    try {
      let prevPrescriptionData = []
      this.state.prevPrescripData?.forEach(element => {
        prevPrescriptionData?.push({
          afternoon: element?.afternoon ? element?.afternoon : '0',
          brand_name: element?.drug_name ? element?.drug_name : element?.doctor_drug_name ? element?.doctor_drug_name : '',
          comments: element?.comments ? element?.comments : '',
          dosage_strength: element.drug_strength ? element.drug_strength : element?.doctor_drug_strength ? element?.doctor_drug_strength : '',
          dosage_type: element.drug_type ? element.drug_type : element?.doctor_dosagetype ? element?.doctor_dosagetype : '',
          drug_id: element.drug_id ? element.drug_id : '',
          duration: element.duration ? element.duration : '',
          duration_mode: element.duration_mode ? element.duration_mode : '1',
          evening: element.evening ? element.evening : '0',
          generic_name: element.generic_name ? element.generic_name : element?.doctor_generic_name ? element?.doctor_generic_name : '',
          instructions: element.instructions ? element.instructions : '',
          is_favorite: element.is_favorite,
          morning: element.morning ? element.morning : '0',
          night: element.night ? element.night : '0',
          is_unit_ml: element?.is_unit_ml ? element?.is_unit_ml : false,
          is_unit_others: element?.is_unit_others ? element?.is_unit_others : false,
          tapper_meds: element?.tapper_meds ? element?.tapper_meds : [],
          is_tapper_meds: element?.tapper_meds?.length === 0 ? false : true,
          is_external_drug: element?.is_external_drug ? element?.is_external_drug : false
        })
      })
      let Array = [...this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : [], ...prevPrescriptionData]
      this.state.prescriptionData.prescription_lines = Array
      this.setState({
        prescriptionData: this.state.prescriptionData,
        openPrevious: false,
        selectedPharmacy: (Array?.length > 0 && Array[0]?.pharmacy_id) ? Array[0]?.pharmacy_id : "",
      }, () => { })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionPrint = () => {
    try {
      let appointmentId = this.state.selectedPrevDate?.appointment ? this.state.selectedPrevDate?.appointment : this.props.PatientData?.appointment_id
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.props.PatientData?.patient_id}&appointment_id=${appointmentId}&date=${this.state.selectedPrevDate?.prescription_date}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPreviousPrescriptionPopup = () => {
    try {
      this.state.prevPrescripData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'drug_name', headerName: 'Brand Name', flex: 0.35,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.drug_name}</Typography>
              <Typography fontSize={'0.8vw'}>{`${params?.row?.generic_name} | ${params?.row?.drug_strength} ${params?.row?.drug_type}`}</Typography>
            </Box>
          )
        },
        {
          field: 'morning', headerName: 'M-A-E-N', flex: 0.2,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{`${params?.row?.morning}${params?.row?.is_unit_ml ? 'ML' : ''} - ${params?.row?.afternoon}${params?.row?.is_unit_ml ? 'ML' : ''} - ${params?.row?.evening}${params?.row?.is_unit_ml ? 'ML' : ''} - ${params?.row?.night}${params?.row?.is_unit_ml ? 'ML' : ''}`}</Typography>
              <Typography fontSize={'0.8vw'}>{`${params?.row?.duration} ${params?.row?.duration_mode === '1' ? 'Days' : params?.row?.duration_mode === '2' ? 'Weeks' : params?.row?.duration_mode === '3' ? 'Months' : params?.row?.duration_mode === '5' ? 'Alternative Days' : 'Years'}`}</Typography>
            </Box>
          )
        },
        {
          field: 'instructions', headerName: 'Usage', flex: 0.2,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'}>{`${this.instructions(params?.row?.instructions)}`}</Typography>
            </Box>
          )
        },
        {
          field: 'comments', headerName: 'Description', flex: 0.25,
          renderCell: (params) => (
            <Box component={'div'}>
              {params?.row?.comments ? params?.row?.comments?.length > 10 ?
                <Tooltip placement='top' title={params?.row?.comments}>
                  <Typography fontSize={'0.9vw'}>{params?.row?.comments?.slice(0, 10) + '...'}</Typography>
                </Tooltip>
                :
                <Typography fontSize={'0.9vw'}>{params?.row?.comments}</Typography> : '-'
              }
            </Box>
          )
        },
      ]
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPrevious}>
            <Box component={'div'} className='eMed_Doc_Notes_Counsellor_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Prescription</Typography>
                <Button
                  onClick={() => {
                    this.setState({ openPrevious: false })
                  }}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} m={'0.5vw'} alignItems={'center'}>
                <Autocomplete
                  size='small'
                  clearIcon
                  sx={{ width: '15vw', mr: '1vw' }}
                  options={this.state.previousPrescripDate}
                  getOptionLabel={(option) => typeof (option) === 'string' ? formatDate(option) : formatDate(option?.prescription_date)}
                  value={this.state.selectedPrevDate}
                  onChange={(e, value) => {
                    this.setState({
                      selectedPrevDate: value
                    }, () => this.getPreviousPrescriptionDatas())
                  }}
                  renderInput={(params) => <TextField {...params} label={'Select Date'} />}
                />
                <Button variant='contained'
                  sx={{ textTransform: 'capitalize', mr: '1vw' }}
                  onClick={() => this.getPreviousPrescriptionPrint()}
                  disabled={this.state.previousPrescripDate?.length > 0 ? false : true}
                >Print</Button>
                <Button variant='contained'
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => this.copyPreviousPrescription()}
                  disabled={this.state.isLocked || this.props.isLocked || (this.state.previousPrescripDate?.length > 0 ? false : true)}
                >Copy</Button>
              </Box>
              <Box component={'div'} sx={{ height: '21vw', m: "0.5vw", overflow: 'auto', border: '1px solid lightgray' }}>
                {/* <DataGrid
                  rows={this.state.prevPrescripData}
                  columns={columns}
                  getRowId={(row) => row['sno']}
                  page={this.state.page}
                  pageSize={this.state.pageSize}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  components={{
                    // Toolbar: CommonGridHeader.CommonToolBar,
                    NoRowsOverlay: () => (
                      <Box className='' sx={{ position: 'relative', top: '5vw', left: '20vw' }}>
                        {'No Records To Be Shown'}
                      </Box>
                    )
                  }}
                  onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                  onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                  rowsPerPageOptions={[10, 20, 30]}
                  pagination
                  headerHeight={40}
                  disableSelectionOnClick
                  loading={this.state.loading}
                /> */}
                <Table size='small' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9dvw', fontWeight: 600 }}>Brand Name</TableCell>
                      <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9dvw', fontWeight: 600 }}>M-A-E-N</TableCell>
                      <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9dvw', fontWeight: 600 }}>Usage</TableCell>
                      <TableCell sx={{ backgroundColor: Colors.Background, fontSize: '0.9dvw', fontWeight: 600 }}>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.prevPrescripData?.length > 0 ? this.state.prevPrescripData?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          {item?.drug_name ? <TableCell>{item?.drug_name ?
                            <Box component={'div'}>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>
                                {item?.drug_name?.length > 15 ? (
                                  <Tooltip placement='top' title={item?.drug_name}>
                                    <div>{item?.drug_name?.slice(0, 15) + '...'}</div>
                                  </Tooltip>
                                ) : (
                                  item?.drug_name
                                )}
                              </Typography>
                              <Typography display={"inline-flex"} fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                {item?.generic_name ? (
                                  item.generic_name.length > 15 ? (
                                    <Tooltip placement='top' title={item.generic_name}>
                                      <div>{item.generic_name.slice(0, 15) + '...'}</div>
                                    </Tooltip>
                                  ) : (
                                    item.generic_name
                                  )
                                ) : (
                                  '-'
                                )} | {item?.drug_strength ? item.drug_strength : '-'} {item?.drug_type || ''}
                              </Typography>
                            </Box> : '-'
                          }</TableCell> :
                            <TableCell>{item?.doctor_drug_name ?
                              <Box component={'div'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>
                                  {item?.doctor_drug_name?.length > 15 ? (
                                    <Tooltip placement='top' title={item?.doctor_drug_name}>
                                      <div>{item?.doctor_drug_name?.slice(0, 15) + '...'}</div>
                                    </Tooltip>
                                  ) : (
                                    item?.doctor_drug_name
                                  )}
                                </Typography>
                                <Typography display={"inline-flex"} fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                  {item?.doctor_generic_name ? (
                                    item.doctor_generic_name.length > 15 ? (
                                      <Tooltip placement='top' title={item.doctor_generic_name}>
                                        <div>{item.doctor_generic_name.slice(0, 15) + '...'}</div>
                                      </Tooltip>
                                    ) : (
                                      item.doctor_generic_name
                                    )
                                  ) : (
                                    '-'
                                  )} | {item?.doctor_drug_strength ? item.doctor_drug_strength : '-'} {item?.doctor_dosagetype || ''}
                                </Typography>
                              </Box> : '-'
                            }</TableCell>}
                          {item?.tapper_meds?.length > 0 ? null :
                            <><TableCell>
                              <Box component={'div'}>
                                <Typography fontSize={item?.is_unit_ml ? '0.8vw' : '0.9vw'} fontWeight={600}>{`${item?.morning ? item?.morning : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.afternoon ? item?.afternoon : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.evening ? item?.evening : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.night ? item?.night : '0'}${item.is_unit_ml ? "ML" : ''}`}</Typography>
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{this.instructions(item?.instructions)}</Typography>
                              </Box>
                            </TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600 }}>{`${item?.duration} ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : item?.duration_mode === '5' ? 'Alternative Days' : 'Years'}`}</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.comments ? item?.comments?.length > 10 ?
                                <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 10) + '...'}</div></Tooltip> :
                                item?.comments : '-'}</TableCell></>}


                          {item?.tapper_meds?.length > 0 ?
                            <TableCell>
                              {item?.tapper_meds?.map((tapList, tapIndex) => {
                                let date = formatDate(tapList?.start_date) + "-" + formatDate(tapList?.end_date)
                                let mean = tapList?.morning + " - " + tapList?.afternoon + " - " + tapList?.evening + " - " + tapList?.night
                                return (
                                  <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                    {mean?.length > 20 ?
                                      <Tooltip placement='top' title={mean}><Typography fontSize={'0.8dvw'} fontWeight={600}>{mean?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                      <Typography fontSize={'0.8dvw'} fontWeight={600}>{`${tapList?.morning ? tapList?.morning : '0'} - ${tapList?.afternoon ? tapList?.afternoon : '0'} - ${tapList?.evening ? tapList?.evening : '0'} - ${tapList?.night ? tapList?.night : '0'}`}</Typography>}
                                    <Typography fontSize={'0.8dvw'}>{this.instructions(tapList?.instructions)}</Typography>
                                    {date?.length > 22 ?
                                      <Tooltip placement='top' title={mean}><Typography fontSize={'0.8dvw'} fontWeight={600}>{date?.slice(0, 18) + '...'}</Typography></Tooltip> :
                                      <Typography fontSize={'0.8dvw'}>{`${tapList?.start_date ? formatDate(tapList?.start_date) : '-'} - ${tapList?.end_date ? formatDate(tapList?.end_date) : '-'}`}</Typography>
                                    }
                                  </Box>
                                )
                              })}
                            </TableCell> : null}
                          {item?.tapper_meds?.length > 0 ?
                            <TableCell>
                              {item?.tapper_meds?.map((tapList, tapIndex) => {
                                return (
                                  <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                    <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiudok55'}</Typography>
                                    <Typography fontSize={'0.8dvw'} fontWeight={600}>{`${tapList?.duration ? tapList?.duration : '-'}`}</Typography>
                                    <Typography fontSize={'0.8dvw'}>{`${tapList?.no_of_dosage ? tapList?.no_of_dosage : '-'}`}</Typography>
                                  </Box>
                                )
                              })}
                            </TableCell> : null}
                          {item?.tapper_meds?.length > 0 ?
                            <TableCell>
                              {item?.tapper_meds?.map((tapList, tapIndex) => {
                                return (
                                  <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                    <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiud55'}</Typography>
                                    <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiudoki'}</Typography>
                                    {tapList?.comments ? tapList?.comments?.length > 10 ?
                                      <Tooltip placement='top' title={tapList?.comments}><Typography fontSize={'0.8dvw'} fontWeight={600}>{tapList?.comments?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                      <Typography fontSize={'0.8dvw'} fontWeight={600}>{tapList?.comments}</Typography> : '-'}
                                  </Box>
                                )
                              })}
                            </TableCell> : null}
                        </TableRow>
                      )
                    }) :
                      <TableRow><TableCell align='center' colSpan={4}>No Records To Be Shown</TableCell></TableRow>}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderFavPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openFav}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Select From Favourite</Typography>
                <Button
                  onClick={() => this.setState({ openFav: false, selectedPrescripFav: [] })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'1vw'}>
                <Autocomplete
                  clearIcon
                  size='small'
                  multiple
                  sx={{ width: '20vw' }}
                  options={this.state.prescriptionFav}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.brand_name}
                  value={this.state.selectedPrescripFav}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({ selectedPrescripFav: newValue })
                  }}
                  renderInput={(params) => <TextField {...params} label='Select Prescription' />}
                />
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.setState({ selectedPrescripFav: [] })}
                >Clear</Button>
                <Button
                  disabled={this.state.selectedPrescripFav?.length < 0 || this.state.transmitted ? true : false}
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => {
                    let Array = [...this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : [], ...this.state.selectedPrescripFav]
                    this.state.prescriptionData.prescription_lines = Array
                    this.setState({ prescriptionData: this.state.prescriptionData, selectedPrescripFav: [], openFav: false }, () => { })
                  }}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewTemplatePopup = () => {
    try {
      let { tempType } = this.state
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPopupAddNew}>
            <Box className='eMed_Doc_Notes_Test_Trans_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'}>
                <Typography fontWeight={600} color={Colors.SecondaryText}>{tempType === 'Add' ? 'Add New Template' : tempType === 'rename' ? 'Rename Template Name' : 'Delete Template'}</Typography>
              </Box>
              <Box component={'div'}>
                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                  {tempType === 'Add' ?
                    <Box component={'div'} display={'flex'}>
                      {this.state.is_pharmacy ? null :
                        <FormControl size='small' sx={{ width: '12vw', mr: '0.5vw' }}>
                          <InputLabel>Select Pharmacy</InputLabel>
                          <Select
                            label='select Pharmacy'
                            value={this.state.selectedPharmacy}
                            disabled={this.state.prescriptionData?.prescription_lines?.length > 0 && this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id === this.state.selectedPharmacy && this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id}
                            onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
                          >
                            {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                              <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>}
                      <TextField
                        size='small'
                        sx={{ width: '12vw' }}
                        value={this.state.templateName}
                        label='Enter Template Name'
                        onChange={(e) => this.setState({ templateName: e.target.value })}
                      />

                    </Box>
                    : <TextField
                      size='small'
                      sx={{ width: '15vw' }}
                      value={this.state.templateName}
                      label='Enter Template Name'
                      onChange={(e) => this.setState({ templateName: e.target.value })}
                    />
                  }
                </Box>

                <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'0.5vw'}>
                  <Button variant='contained'
                    sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                    onClick={() => {
                      this.postTemplate()
                    }}
                  >Add</Button>
                  <Button variant='outlined'
                    sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                    onClick={() => this.setState({ tempType: '', openPopupAddNew: false, templateName: "" })}
                  >Close</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionPrint = () => {
    try {
      RestAPIService.getAllTwo(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_PRINT + `?prescription_id=${this.state.prescriptionData?.prescription_id ? this.state.prescriptionData?.prescription_id : ""}&clinic_id=${this.state.clinicId ? this.state.clinicId : ""}&language=${this.state.selectedLanguage}&appointment_id=${this.props.PatientData?.appointment_id ? this.props.PatientData?.appointment_id : ""}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    try {
      const { t } = this.props
      let prescriptionData = this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : this.state.prescriptionData
      return (
        <ShowComponents onIntersection={this.GetIntialData.bind(this)}>
          <Box minHeight={'30vh'} overflow={'auto'}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: '0.5dvw 1dvw' }} visibility={this.state.SelectedEditIndex === null ? 'visible' : 'hidden'}>
              <Box>
                {this.state.is_pharmacy ? null :
                  <Button
                    className='Common_Btn_Min_Width'
                    disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                    // onClick={() => this.getPharmacyList(false, true)}
                    onClick={() => {
                      let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
                      let pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
                      this.setState({
                        selectedPharmacy: prescriptionData[0]?.pharmacy_id ? prescriptionData[0]?.pharmacy_id : (pharmaList?.length ? pharmaList[0]?.pharmacy_id : "")
                      }, () => {
                        this.postPrescriptionTransmit()
                      })
                    }
                    }
                  >
                    <Tooltip placement='top' title='Transmit'>
                      <Box component={'img'} src={(this.state.isLocked || this.props.isLocked || this.state.transmitted) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                    </Tooltip>
                  </Button>}
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => {
                    this.setState({ openPrevious: false }, () => {
                      this.getPreviousPrescriptionDates()
                    })
                  }}
                >
                  <Tooltip placement='top' title={this.state.isOPT ? 'Previous Medication' : "Previous Prescription"}>
                    <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.1vw'} width={'1.1vw'} />
                  </Tooltip>
                </Button>
                <Button
                  className='Common_Btn_Min_Width'
                  disabled={this.state.isLocked || this.props.isLocked}
                  onClick={() => this.setState({ openFav: true })}
                >
                  <Tooltip placement='top' title='favorite'>
                    <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.3vw'} width={'1.3vw'} />
                  </Tooltip>
                </Button>
                {this.state.Recalled == false && this.state.transmitted && !this.props.isLocked ? <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => { this.setState({ recallPopup: true }) }}
                >
                  <Tooltip placement='top' title='Recall'>
                    <div style={{ backgroundColor: "#b8170d", borderRadius: "0.2vw", width: "1.4vw", height: "1.5vw" }}>
                      <Box component={'img'} src={ImagePaths.Recall.default} height={'1.1vw'} width={'1.1vw'} />
                    </div>
                  </Tooltip>
                </Button> : null}
              </Box>
              <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '0.5dvw' }}>
                <Autocomplete
                  size='small'
                  disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                  sx={{ width: '13vw', mr: '0.5vw' }}
                  options={this.state.prescriptionTemplateList?.filter(item => {
                    const pharmacyId = (this.state.prescriptionData?.prescription_lines?.length > 0 && this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id) ||
                      (this.state.prescriptionData?.length > 0 && this.state.prescriptionData[0]?.pharmacy_id) ||
                      this.state.selectedPharmacy;
                    return pharmacyId ? item?.pharmacy_id === pharmacyId : true
                  })}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option.template_name}
                  value={this.state.selectedTemplate}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({
                      selectedTemplate: newValue
                    }, () => {
                      if (newValue) { this.GetPrescriptionData() }
                    })
                  }}
                  renderInput={(params) => <TextField {...params} me label='Select Template' />}
                  PaperComponent={({ children }) => {
                    return (
                      <Paper component={'div'}>
                        {children}
                        {this.state.showAddTemplate ? <Button
                          color="primary"
                          fullWidth
                          sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                          onMouseDown={(e) => {
                            this.setState({ openPopupAddNew: true, tempType: 'Add' })
                          }}
                        >
                          Add Template
                        </Button> : null}
                        {/* {this.state.selectedTemplate ?
                          <Box component={'div'}>
                            <Button
                              color="primary"
                              fullWidth
                              sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                              onMouseDown={(e) => {
                                this.setState({ openPopupAddNew: true, tempType: 'rename', templateName: this.state.selectedTemplate?.template_name })
                              }}
                            >
                              Rename
                            </Button>
                            <Button
                              color="primary"
                              fullWidth
                              sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                              onMouseDown={(e) => {
                                this.setState({ deletePopup: true })
                              }}
                            >
                              Delete
                            </Button>
                          </Box> : null
                        } */}

                      </Paper>
                    )
                  }}
                />
                <Box component={'div'}>
                  <Select
                    size='small'
                    sx={{ width: '10vw' }}
                    value={this.state.selectedLanguage}
                    onChange={(e) => this.setState({ selectedLanguage: e.target.value })}
                  >
                    {this.state.languageList?.map((item, index) => (
                      <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box component={'div'}>
                  <Button
                    className='Common_Btn_Min_Width'
                    onClick={() => { this.getPrescriptionPrint() }}
                    disabled={!(prescriptionData?.length > 0)}
                  >
                    <Tooltip placement='top' title='Print'>
                      <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                    </Tooltip>
                  </Button>
                </Box>
                <Button
                  variant='contained'
                  disabled={this.props.isLocked || this.state.transmitted}
                  size='small'
                  onClick={() => { this.PostPrescriptionData() }}>Save</Button>
              </Box>
            </Box>
            <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell width={'2%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Favourite</TableCell>
                    <TableCell width={'31%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Prescription</TableCell>
                    <TableCell width={'28%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>M-A-E-N</TableCell>
                    <TableCell width={'15%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Duration</TableCell>
                    <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
                    <TableCell width={'5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prescriptionData?.length > 0 ? prescriptionData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box component={'div'}>
                          {/* <Button sx={{ visibility: (item?.drug_id || item?.drug_ms_id) ? "visible" : "hidden", justifyContent: 'flex-start', paddingTop: '0.5vw', }} */}
                          <Button sx={{ justifyContent: 'flex-start', paddingTop: '0.5vw', }}
                            className='Common_Btn_Min_Width'
                            disabled={this.props.isLocked || this.state.transmitted}
                            onClick={() => {
                              this.state.prescriptionData.prescription_lines[index].is_favorite = !this.state.prescriptionData?.prescription_lines[index].is_favorite
                              this.setState({
                                prescriptionData: this.state.prescriptionData,
                                selectedPharmacy: (this.state.prescriptionData?.prescription_lines?.length > 0 && this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id) ?
                                  this.state.prescriptionData?.prescription_lines[0]?.pharmacy_id : "",
                              },
                                () => { this.props.getPrescriptionData(this.state.prescriptionData?.prescription_lines || []) })
                            }}>
                            <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />

                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>{item?.brand_name ?
                        <Box component={'div'}>
                          <Typography fontSize={'0.9vw'} fontWeight={600}>
                            {item?.brand_name?.length > 15 ? (
                              <Tooltip placement='top' title={item?.brand_name}>
                                <div>{item?.brand_name?.slice(0, 15) + '...'}</div>
                              </Tooltip>
                            ) : (
                              item?.brand_name
                            )}
                          </Typography>
                          <Typography display={"inline-flex"} fontSize={'0.8vw'} color={Colors.SecondaryText}>
                            {item?.generic_name ? (
                              item.generic_name.length > 15 ? (
                                <Tooltip placement='top' title={item.generic_name}>
                                  <div>{item.generic_name.slice(0, 15) + '...'}</div>
                                </Tooltip>
                              ) : (
                                item.generic_name
                              )
                            ) : (
                              '-'
                            )} | {item?.dosage_strength ? item.dosage_strength : '-'} {item?.dosage_type || ''}
                          </Typography>
                        </Box> : '-'
                      }</TableCell>
                      {item?.tapper_meds?.length > 0 ? null :
                        <><TableCell>
                          <Box component={'div'}>
                            <Typography fontSize={item?.is_unit_ml ? '0.8vw' : '0.9vw'} fontWeight={600}>{`${item?.morning ? item?.morning : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.afternoon ? item?.afternoon : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.evening ? item?.evening : '0'}${item.is_unit_ml ? "ML" : ''} - ${item?.night ? item?.night : '0'}${item.is_unit_ml ? "ML" : ''}`}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{this.instructions(item?.instructions)}</Typography>
                          </Box>
                        </TableCell>
                          <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600 }}>{`${item?.duration} ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : item?.duration_mode === '5' ? 'Alternative Days' : 'Years'}`}</TableCell>
                        </>}

                      {/* Tapper Med Table Column Starts ========> */}
                      {item?.tapper_meds?.length > 0 ?
                        <TableCell>
                          {item?.tapper_meds?.map((tapList, tapIndex) => {
                            let date = formatDate(tapList?.start_date) + "-" + formatDate(tapList?.end_date)
                            let mean = tapList?.morning + " - " + tapList?.afternoon + " - " + tapList?.evening + " - " + tapList?.night
                            return (
                              <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                {mean?.length > 20 ?
                                  <Tooltip placement='top' title={mean}><Typography fontSize={'0.8dvw'} fontWeight={600}>{mean?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                  <Typography fontSize={'0.8dvw'} fontWeight={600}>{`${tapList?.morning ? tapList?.morning : '0'} - ${tapList?.afternoon ? tapList?.afternoon : '0'} - ${tapList?.evening ? tapList?.evening : '0'} - ${tapList?.night ? tapList?.night : '0'}`}</Typography>}
                                <Typography fontSize={'0.8dvw'}>{this.instructions(tapList?.instructions)}</Typography>
                                {date?.length > 22 ?
                                  <Tooltip placement='top' title={mean}><Typography fontSize={'0.8dvw'} fontWeight={600}>{date?.slice(0, 18) + '...'}</Typography></Tooltip> :
                                  <Typography fontSize={'0.8dvw'}>{`${tapList?.start_date ? formatDate(tapList?.start_date) : '-'} - ${tapList?.end_date ? formatDate(tapList?.end_date) : '-'}`}</Typography>
                                }
                              </Box>
                            )
                          })}
                        </TableCell> : null}
                      {item?.tapper_meds?.length > 0 ?
                        <TableCell>
                          {item?.tapper_meds?.map((tapList, tapIndex) => {
                            return (
                              <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiudok55'}</Typography>
                                <Typography fontSize={'0.8dvw'} fontWeight={600}>{`${tapList?.duration ? tapList?.duration : '-'}`}</Typography>
                                {tapList?.no_of_dosage ? tapList?.no_of_dosage?.length > 10 ?
                                  <Tooltip placement='top' title={tapList?.no_of_dosage}><Typography fontSize={'0.8dvw'}>{tapList?.no_of_dosage?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                  <Typography fontSize={'0.8dvw'}>{tapList?.no_of_dosage}</Typography> : '-'}
                              </Box>
                            )
                          })}
                        </TableCell> : null}
                      {/* {item?.tapper_meds?.length > 0 ?
                        <TableCell>
                          {item?.tapper_meds?.map((tapList, tapIndex) => {
                            return (
                              <Box component={'div'} key={tapIndex} mb={'0.2dvw'}>
                                <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiud55'}</Typography>
                                <Typography fontSize={'0.8dvw'} visibility={'hidden'}>{'kjsiudoki'}</Typography>
                                {tapList?.comments ? tapList?.comments?.length > 10 ?
                                  <Tooltip placement='top' title={tapList?.comments}><Typography fontSize={'0.8dvw'} fontWeight={600}>{tapList?.comments?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                  <Typography fontSize={'0.8dvw'} fontWeight={600}>{tapList?.comments}</Typography> : '-'}
                              </Box>
                            )
                          })}
                        </TableCell> : null} */}
                      {/* Tapper Med Table Column Ends ========> */}
                      <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600 }}>{item?.comments ? item?.comments?.length > 10 ?
                        <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 10) + '...'}</div></Tooltip> :
                        item?.comments : '-'}</TableCell>
                      <TableCell >
                        <Box sx={{ fontSize: '0.9vw', fontWeight: 600, display: 'flex' }}>
                          <CommonEditButton size={"1.5vw"} disable={this.props.isLocked || this.state.transmitted} onClick={this.onEditHandler.bind(this, item, index)} />
                          <CommonDeleteButton size={"1.5vw"} disable={this.props.isLocked || this.state.SelectedEditIndex !== null || this.state.transmitted} onClick={this.onDeleteHandler.bind(this, item, index)} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell />
                      <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.renderPreviousPrescriptionPopup()}
          {this.renderNewTemplatePopup()}
          {this.renderFavPopup()}
          {this.renderRecallPopup()}
        </ShowComponents>
      )
    }
    catch (e) {
      console.log(e.message);
      return null
    }
  }
}
export default withTranslation()(DocPrescriptionLeft)
